import { Status } from "../enums";
import { lightTheme } from "@megapolis/uikit/theme";

export const StatusDictionaries = {
    [Status.Inactive]: {
        name: "Выключена",
        code: Status.Inactive,
        color: lightTheme.colors.gray.basic,
    },
    [Status.GreenStreet]: {
        name: "Зеленая улица",
        code: Status.GreenStreet,
        color: lightTheme.colors.green.basic,
    },
    [Status.Coordination]: {
        name: "Координация",
        code: Status.Coordination,
        color: lightTheme.colors.purple.basic,
    },
    [Status.AllRed]: {
        name: "Всем красный",
        code: Status.AllRed,
        color: lightTheme.colors.red.basic,
    },
};

export const StatusDictionariesDictionaryList = Object.values(StatusDictionaries);
