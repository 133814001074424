import { makePath2DDrawer } from "../drawing.common";
import { RectLikeProps } from "../drawing.types";

export interface DrawRectProps extends RectLikeProps {
    width: number;
    height: number;
    fillColor?: string;
    border?: {
        width: number;
        color: string;
    };
}

export const drawRect = makePath2DDrawer<DrawRectProps>((ctx, props) => {
    const path = new Path2D();
    path.rect(props.x, props.y, props.width, props.height);
    path.closePath();
    if (props.fillColor) {
        ctx.fillStyle = props.fillColor;
        ctx.fill(path);
    }
    if (props.border) {
        ctx.lineWidth = props.border.width;
        ctx.strokeStyle = props.border.color;
        ctx.stroke(path);
    }
    return path;
});
