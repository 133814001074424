import { RectBBox } from "./bounds";
import { CanvasObject, CanvasObjectProps } from "./canvas-object";

export type ContainerProps = CanvasObjectProps & {
    width?: number;
    height?: number;
};

export class Container<Child extends CanvasObject = CanvasObject> extends CanvasObject {
    protected _children: Child[] = [];
    protected _width: number;
    protected _height: number;

    constructor(props?: ContainerProps) {
        super(props);
        this._width = props?.width ?? 0;
        this._height = props?.height ?? 0;
    }

    public get children() {
        return this._children;
    }

    public get width() {
        return this._width;
    }

    public set width(value: number) {
        this._width = value;
    }

    public get height() {
        return this._height;
    }

    public set height(value: number) {
        this._height = value;
    }

    public setBounds(bounds: RectBBox) {
        this.x = bounds.x;
        this.y = bounds.y;
        this.width = bounds.width;
        this.height = bounds.height;
    }

    public getBounds() {
        return new RectBBox(this.x, this.y, this.width, this.height);
    }

    public addChild(...children: Child[]) {
        for (const child of children) {
            this.children.push(child);
        }
    }

    public removeChild(child: Child) {
        for (let i = 0; i < this.children.length; i++) {
            if (child !== this.children[i]) continue;
            this.children[i].destroy();
            this.children.splice(i, 1);
            break;
        }
    }

    public removeChildren() {
        for (const child of this._children) {
            child.destroy();
        }
        // @ts-ignore
        this._children = null;
    }

    public sortChildren() {
        this.children.sort((a, b) => a.zIndex - b.zIndex);
    }

    public destroy() {
        this.removeChildren();
        // @ts-ignore
        this._children = null;
    }

    protected _render(ctx: CanvasRenderingContext2D) {
        for (const child of this.children) {
            child.render(ctx);
        }
    }

    protected _update(deltaT: number) {
        for (const child of this.children) {
            child.update?.(deltaT);
        }
    }
}
