import { memo } from "react";
import { ControlBlocks } from "./control-blocks";
import { CooGroupCycleEditorProps } from "./coo-group-cycle-editor.types";
import { useCycleEditor } from "./coo-group-cycle-editor.hooks";
import * as Markup from "./coo-group-cycle-editor.styles";

export const CooGroupCycleEditor = memo((props: CooGroupCycleEditorProps) => {
    const { cooGroupEditor, controlsSettings, canvasRef } = useCycleEditor(props);

    return (
        <Markup.Container>
            {props.isEditMode && (
                <ControlBlocks
                    cycles={controlsSettings}
                    onCycleShiftChange={cooGroupEditor?.changeCycleShift}
                    onCycleChange={cooGroupEditor?.changeCycle}
                    onCycleTimeChange={cooGroupEditor?.changeCycleTime}
                    onCycleSpeedChange={cooGroupEditor?.changeCycleSpeed}
                    onApplyCycleSpeed={cooGroupEditor?.toggleIsSpeedInSync}
                />
            )}
            <Markup.Canvas ref={canvasRef} />
        </Markup.Container>
    );
});
