import { REACT_APP_KSODD_LAYER } from "env-data";
import mapboxgl, { CustomLayerInterface, Map } from "mapbox-gl";
import { TrafficLightDispatcher } from "trafficlight-dispatcher";
import { DetectorStateData, DetectorLaneBusyData } from "../traffic-light-detector.types";

const lineWidth: mapboxgl.Expression = ["interpolate", ["linear", 1], ["zoom"], 17, 0.5, 20, 2];

const detectorColor = "#7D09F1";

export interface TrafficLightDetectorLayerProps {
    id: string;
    dispatcher: TrafficLightDispatcher;
}

export class TrafficcLightDetectorLayer implements CustomLayerInterface {
    public type: "custom" = "custom";
    public renderingMode?: "2d" | "3d" | undefined;
    public readonly id: string;

    public map: Map;
    private _map?: Map;
    private _dispatcher: TrafficLightDispatcher;

    constructor(map: mapboxgl.Map, props: TrafficLightDetectorLayerProps) {
        this.map = map;
        this.id = props.id;
        this._dispatcher = props.dispatcher;
        this.addLayer();
    }

    public addLayer = () => {
        this.map.addLayer(this);
    };

    public readonly destroy = () => {
        if (this.map.getLayer(this.id)) {
            this.map.removeLayer(this.id);
        }
    };

    render(): void {}

    onAdd(map: Map) {
        this._map = map;
        const layerId = this.id;

        const ksoddLayerId = `${REACT_APP_KSODD_LAYER}_features`;
        const videoLayerId = `${layerId}_video`;
        const videoStrokeLayerId = `${layerId}_video-stroke`;
        const loopLayerId = `${layerId}_loop`;
        const loopStrokeLayerId = `${layerId}_loop-stroke`;

        if (!this._map.getSource(ksoddLayerId)) return;

        if (this._map.getLayer(ksoddLayerId)) {
            map.setPaintProperty(`${REACT_APP_KSODD_LAYER}_features`, "fill-opacity", [
                "match",
                ["get", "feature_type"],
                "detector_zone",
                0,
                1,
            ]);
        }

        if (!this._map.getLayer(videoLayerId)) {
            map.addLayer({
                "id": videoLayerId,
                "type": "fill",
                "source": `${REACT_APP_KSODD_LAYER}_features`,
                "source-layer": "features",
                "minzoom": 16,
                "paint": {
                    "fill-antialias": true,
                    "fill-opacity": 0.2,
                    "fill-color": ["match", ["feature-state", "active"], "1", detectorColor, "#C6C6C6"],
                },
                "filter": ["all", ["==", "feature_type", "detector_zone"], ["==", "type", "video"]],
            });
        }
        if (!this._map.getLayer(videoStrokeLayerId)) {
            map.addLayer({
                "id": videoStrokeLayerId,
                "type": "line",
                "source": `${REACT_APP_KSODD_LAYER}_features`,
                "source-layer": "features",
                "minzoom": 16,
                "paint": {
                    "line-width": lineWidth,
                    "line-opacity": 1.0,
                    "line-color-transition": {
                        duration: 300,
                    },
                    "line-color": ["match", ["feature-state", "active"], "1", detectorColor, "#C6C6C6"],
                },
                "filter": ["all", ["==", "feature_type", "detector_zone"], ["==", "type", "video"]],
            });
        }
        if (!this._map.getLayer(loopLayerId)) {
            map.addLayer({
                "id": loopLayerId,
                "type": "fill",
                "minzoom": 16,
                "source": `${REACT_APP_KSODD_LAYER}_features`,
                "source-layer": "features",
                "paint": {
                    "fill-antialias": true,
                    //  "fill-color": "#FF0000",
                    "fill-opacity": 0.2,
                    "fill-color": ["match", ["feature-state", "busy"], "1", detectorColor, "#C6C6C6"],
                },
                "filter": ["all", ["==", "feature_type", "detector_zone"], ["==", "type", "inductive_loop"]],
            });
        }
        if (!this._map.getLayer(loopLayerId)) {
            map.addLayer({
                "id": loopStrokeLayerId,
                "type": "line",
                "source": `${REACT_APP_KSODD_LAYER}_features`,
                "source-layer": "features",
                "minzoom": 16,
                "paint": {
                    "line-width": lineWidth,
                    "line-opacity": 1.0,
                    "line-color-transition": {
                        duration: 1000,
                    },
                    "line-color": ["match", ["feature-state", "busy"], "1", detectorColor, "#C6C6C6"],
                },
                "filter": ["all", ["==", "feature_type", "detector_zone"], ["==", "type", "inductive_loop"]],
            });
        }
    }

    public updateLaneBusy(data: DetectorLaneBusyData) {
        const map = this._map;
        if (!map) return;

        const { isBusy, lane, trafficLightId } = data;

        const detectorFeatureId = `${trafficLightId}_${lane.directionNumber}_${lane.num}`;

        const features = map.querySourceFeatures(`${REACT_APP_KSODD_LAYER}_features`, {
            sourceLayer: "features",
            filter: [
                "all",
                ["==", "feature_type", "detector_zone"],
                ["==", "type", "inductive_loop"],
                ["==", "detectorId", detectorFeatureId],
            ],
            validate: true,
        });
        features.forEach((f) =>
            map.setFeatureState(
                {
                    id: f.id,
                    source: `${REACT_APP_KSODD_LAYER}_features`,
                    sourceLayer: "features",
                },
                { busy: isBusy ? "1" : "0" }
            )
        );
    }

    public updateDetectorState(data: DetectorStateData) {
        const map = this._map;
        if (!map) return;
        const { trafficLightId, lane, metrics } = data;

        const detectorFeatureId = `${trafficLightId}_${lane.directionNumber}_${lane.num}`;

        const features = map.querySourceFeatures(`${REACT_APP_KSODD_LAYER}_features`, {
            sourceLayer: "features",
            filter: [
                "all",
                ["==", "feature_type", "detector_zone"],
                ["==", "type", "video"],
                ["==", "detectorId", detectorFeatureId],
            ],
            validate: true,
        });

        features.forEach((f) =>
            map.setFeatureState(
                {
                    id: f.id,
                    source: `${REACT_APP_KSODD_LAYER}_features`,
                    sourceLayer: "features",
                },
                { active: Number(f.properties!["detectorSegment"]) <= metrics.total ? "1" : "0" }
            )
        );
    }

    onRemove(map: Map) {
        this._map = undefined;
    }
}
