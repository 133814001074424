import { reaction } from "mobx";
import { AppStateManager } from "app/store";
import { MapController } from "map-controller";
import { RouteDomain } from "app-domain";
import { pointsMapper } from "./coo-group-editor-broker.utils";

export class CooGroupEditorBroker {
    private markerClickEffect?: VoidFunction;
    private pointsChangeEffect?: VoidFunction;
    private routeSourceChangeEffect?: VoidFunction;
    private isNewChangeEffect?: VoidFunction;

    constructor(protected mapController: MapController, private appState: AppStateManager) {
        this.mapController.on(this.mapController.events.load, this.handleOnMapLoad);
    }

    public destroy() {
        this.mapController.off(this.mapController.events.load, this.handleOnMapLoad);
        this.unsubscribeOnMapEvents();
        this.unsubscribeOnStoreChange();
        this.isNewChangeEffect?.();
    }

    private get routeBuildingController() {
        return this.mapController.layerControllers.routeBuildingLayerController;
    }

    private handleRouteAddMarker = async (lngLat: LatLng) => {
        await this.appState.cooGroupEditor.setAddressByLngLat(lngLat);
    };

    private handlePointsChange = (points: RouteDomain.Point[]) => {
        const prepared = points.map(pointsMapper).filter((item) => item.id >= 0);
        this.routeBuildingController?.setMarkersData?.(prepared);
    };

    private setIsMarkerAddable = (change: boolean) => {
        this.routeBuildingController?.setIsMarkerAddable(change);
    };

    private setRouteData = (route?: RouteDomain.MapRouteFeatures) => {
        this.routeBuildingController?.setData(route);
    };

    private handleSetSubscription = (value: boolean) => {
        if (value) {
            this.subscribeOnMapEvents();
            this.subscribeOnStoreChange();

            this.handlePointsChange(this.appState.cooGroupEditor.points);
            this.setRouteData(this.appState.cooGroupEditor.route);
        } else {
            this.unsubscribeOnMapEvents();
            this.unsubscribeOnStoreChange();
        }
    };

    private subscribeOnMapEvents() {
        this.routeBuildingController
            ?.on(this.routeBuildingController.events.addMarker, this.handleRouteAddMarker)
            .on(this.routeBuildingController.events.moveMarker, this.appState.cooGroupEditor.handleMoveOnMap);
    }

    private unsubscribeOnMapEvents() {
        this.routeBuildingController
            ?.off(this.routeBuildingController.events.addMarker, this.handleRouteAddMarker)
            .off(this.routeBuildingController.events.moveMarker, this.appState.cooGroupEditor.handleMoveOnMap);
    }

    private subscribeOnStoreChange() {
        this.pointsChangeEffect = reaction(() => this.appState.cooGroupEditor.points, this.handlePointsChange);
        this.markerClickEffect = reaction(() => this.appState.cooGroupEditor.isEmptyPoint, this.setIsMarkerAddable);
        this.routeSourceChangeEffect = reaction(() => this.appState.cooGroupEditor.route, this.setRouteData);
    }

    private unsubscribeOnStoreChange() {
        this.markerClickEffect?.();
        this.pointsChangeEffect?.();
        this.routeSourceChangeEffect?.();
    }

    private handleOnMapLoad = () => {
        this.isNewChangeEffect = reaction(() => this.appState.cooGroupEditor.isNew, this.handleSetSubscription);
        this.handleSetSubscription(this.appState.cooGroupEditor.isNew);
    };
}
