import { TrafficLightSockets } from "api";
import { TrafficLightDomain } from "app-domain";
import { Store } from "app";
import { GovernanceInfo } from "app-domain/traffic-light";
import { ISubscriberService } from "./traffic-light-stream-subscriber.types";
import { ITrafficLightStore } from "../interfaces";

const {
    Constants: { malfunctionCodeDictionaryMap },
    Enums: { EventTypeCode },
} = TrafficLightDomain;

type Message = TrafficLightSockets.TrafficLightStreamMessage;

export class Subscriber implements TrafficLightSockets.TrafficLightStreamSubscriber {
    constructor(
        private trafficLightStore: ITrafficLightStore,
        private remoteControlStore: Store.TrafficLightRemoteControls,
        private trafficLightService: ISubscriberService
    ) {}

    public onMessage(msg: Message) {
        const trafficLightId = msg[0];
        const trafficLight = this.trafficLightStore.getById(trafficLightId);
        if (!trafficLight) return;
        const processedMessage = this.processMessage(msg, trafficLight);
        trafficLight.processSignalMessage(processedMessage);
        trafficLight.updateState(processedMessage.state);
        const { eventType } = processedMessage;

        if (
            eventType === EventTypeCode.ReleaseGovernanceEvent ||
            eventType === EventTypeCode.TakeGovernanceEvent ||
            eventType === EventTypeCode.UpdateGovernanceEvent
        ) {
            console.group("traffic-light-governance-event");
            console.log("traffic-light", trafficLight);
            console.log("processed message", processedMessage);
            console.log("original message", msg);
            console.groupEnd();
        }

        if (eventType === EventTypeCode.PlannedGovernanceTimeEvent) {
            this.trafficLightStore.loadGovernancePlanned(trafficLightId);
        }

        if (processedMessage.eventType === EventTypeCode.ReleaseGovernanceEvent) {
            this.remoteControlStore.loadPlannedControls();
            return this.remoteControlStore.removeRemoteControl(trafficLightId);
        }

        if (processedMessage.eventType === EventTypeCode.TakeGovernanceEvent) {
            const actualGovernance = processedMessage.state.governanceInfo;
            if (typeof actualGovernance?.id !== "number") return;
            // Случай, когда СО ранее не контролировался
            trafficLight.governanceInfo = actualGovernance;
            return this.remoteControlStore.loadRemoteControlByGovernanceId(actualGovernance.id);
        }

        // Случай обновления информации по пульту
        // Тут не используется actualGovernanceId, потому что бекенд при завершении команды
        // по какой-то причине ставит актуальный governanceId в null, хотя пульт еще в руках у юзера
        // поэтому мы используем для обновления информации по пульту текущий governanceId ассоциированный с светофором
        // Например: завершение команды
        if (processedMessage.eventType === EventTypeCode.UpdateGovernanceEvent) {
            this.remoteControlStore.loadPlannedControls();
            const control = this.remoteControlStore.getRemoteControlByTrafficLightId(trafficLightId);
            if (!trafficLight.governanceInfo || !control) return;
            return this.remoteControlStore.loadRemoteControlByGovernanceId(trafficLight.governanceInfo.id);
        }
    }

    public async onReconnect() {
        const newStates = await this.trafficLightService.getTrafficLightsStates();
        for (const state of newStates) {
            const trafficLight = this.trafficLightStore.getById(state.id);
            if (!trafficLight) return;
            trafficLight.updateState(state);
        }
    }

    private processMessage(
        msg: Message,
        trafficLight: TrafficLightDomain.TrafficLight
    ): TrafficLightDomain.TrafficLightMessage {
        const governanceId = msg[11];
        return {
            id: trafficLight.id,
            dateTime: msg[8],
            eventType: msg[9],
            controllerDateTime: msg[20],
            state: {
                id: trafficLight.id,
                condition: trafficLight.condition,
                program: msg[1],
                phase: msg[2],
                phaseTime: msg[3],
                secondsGone: msg[4],
                isPromTime: msg[6],
                nextPhase: msg[7],
                malfunctionType: msg[10],
                governanceInfo: governanceId ? new GovernanceInfo(governanceId, msg[24], msg[25]) : null,
                controlMode: msg[13],
                status: msg[14],
                isGuidedAdaptiveAllowed: msg[17],
                preStatus: msg[18],
                prePhase: msg[19],
                malfunctions: msg[21].map((malfunction) => ({
                    code: malfunction[0],
                    name:
                        malfunctionCodeDictionaryMap[malfunction[0] as TrafficLightDomain.Enums.MalfunctionType]
                            ?.name ?? "",
                    description: malfunction[1],
                    isCritical: malfunction[2],
                })),
                isLocalAdaptiveEnabled: msg[22],
                plannedGovernanceTime: msg[23] ? new Date(msg[23] * 1000).toString() : null,
            },
        };
    }
}
