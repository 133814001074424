import { memo, useCallback } from "react";
import { SyncButton, Button, ButtonContent } from "../components";
import { CycleSpeedInput } from "./cycle-speed-input";
import { Props } from "./speed-settings.types";
import { SpeedKind } from "../../coo-group-cycle-editor.types";
import * as Markup from "./speed-settings.styles";

export const SpeedSettings = memo((props: Props) => {
    const { onChange, onApplyCycleSpeed, cycleId } = props;

    console.log("update");

    const handleChange = useCallback(
        (kind: SpeedKind, value: number) => {
            onChange?.(cycleId, kind, value);
        },
        [cycleId, onChange]
    );

    const handleApplyDirectSpeed = useCallback(() => {
        onApplyCycleSpeed?.(SpeedKind.direct, cycleId);
    }, [cycleId, onApplyCycleSpeed]);

    const handleApplyReverseSpeed = useCallback(() => {
        onApplyCycleSpeed?.(SpeedKind.reverse, cycleId);
    }, [cycleId, onApplyCycleSpeed]);

    return (
        <Markup.Container>
            <Markup.SyncBlock>
                <Button priority="secondary" content={ButtonContent.Text} text="ДТМ" />
                <SyncButton />
            </Markup.SyncBlock>
            <Markup.SyncBlock>
                <Markup.DirectSpeedIcon />
                <CycleSpeedInput kind={SpeedKind.direct} value={props.directSpeed} onChange={handleChange} />
                <SyncButton onClick={handleApplyDirectSpeed} isActive={props.areDirectSpeedsApplied} />
            </Markup.SyncBlock>
            <Markup.SyncBlock>
                <Markup.ReverseSpeedIcon />
                <CycleSpeedInput kind={SpeedKind.reverse} value={props.reverseSpeed} onChange={handleChange} />
                <SyncButton onClick={handleApplyReverseSpeed} isActive={props.areReverseSpeedsApplied} />
            </Markup.SyncBlock>
            <Markup.Distance>{props.distance} м</Markup.Distance>
        </Markup.Container>
    );
});
