import { BaseApi } from "api/utils";
import { QueryParamsBuilder } from "lib";
import * as Env from "env-data";
import { GetAddressByLatLngResponse } from "./yandex-geocode.types";

export class YandexGeocodeAPI extends BaseApi {
    /** Центр области поиска */
    private searchAreaCenter = Env.REACT_APP_MAP_CENTER.toString();

    constructor() {
        super(Env.REACT_APP_API_YANDEX_GEOCODE);
    }

    public getAddressByLatLng(params: LatLng): GetAddressByLatLngResponse {
        const { lng, lat } = params;
        const queryParamsBuilder = new QueryParamsBuilder();
        const queryParams = queryParamsBuilder
            .addParam("geocode", `${lat},${lng}`)
            .addParam("sco", "latlong")
            .addParam("format", "json")
            .addParam("spn", Env.REACT_APP_GEOCODE_SPAN_SEARCH_AREA)
            .addParam("ll", this.searchAreaCenter)
            .addParam("rspn", "1")
            .addParam("results", "1")
            .addParam("apikey", Env.REACT_APP_API_YANDEX_GEOCODE_API_KEY)
            .toString();

        const url = this.buildUrl(`/?${queryParams}`);
        return this.request({ url });
    }
}
