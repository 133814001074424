import { Facility } from "api";
import { TrafficLightDomain } from "app-domain";
import { IPagedCollectionMap } from "shared/types";
import { FileHelper } from "lib";
import { GetEventsArgs } from "./traffic-light-history-service.types";
import { TrafficLightHistoryEventMapper } from "../mappers";
import { FileResponseHelper } from "../file-response-helper";

export class TrafficLightHistoryService {
    private mapper = new TrafficLightHistoryEventMapper();

    constructor(private api: Facility.FacilityAPI) {}

    public async getHistoryEvents(
        args: GetEventsArgs
    ): Promise<IPagedCollectionMap<number, TrafficLightDomain.HistoryEvent>> {
        const result = await this.api.getFacilityLogs({
            facilityId: args.trafficLightId,
            filter: args.filter,
            includeMalfunctionDetails: true,
            includeRaw: true,
            /** Временно */
            count: 10000,
        });
        const items = new Map();
        result.items.forEach((item, index) => {
            const id = Date.now() + index;
            item.id = id;
            const mappedItem = this.mapper.fromAPI(item);
            items.set(id, mappedItem);
        });

        return {
            pageIndex: result.pageIndex,
            totalCount: result.totalCount,
            pageSize: result.pageSize,
            items,
        };
    }

    public async downloadHistorySnapshotFile({ trafficLightId, filter }: GetEventsArgs) {
        const response = await this.api.getHistorySnapshot({ filter, facilityId: trafficLightId });
        const [blob, fileName] = await FileResponseHelper.getFileMetaData({ response });
        FileHelper.downloadFile(new File([blob], fileName));
    }
}
