import { useState, useRef, useEffect, useCallback } from "react";
import { useClickOutside } from "shared/hooks";
import { Props, Position } from "./dropdown.types";

export const useDropdown = (props: Props) => {
    const { horizontal = "center", vertical = "bottom", isAutoClosable = true, onClose } = props;
    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen ?? false);
    const [position, setPosition] = useState<Position>({});
    const [anchorRef, setAnchorRef] = useState<HTMLElement | null>(null);
    const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);
    const isMounted = useRef(true);

    const calculatePosition = useCallback(() => {
        if (!anchorRef || !contentRef || !isOpen || !isMounted.current) return;
        const anchorBounds = anchorRef.getBoundingClientRect();
        const contentBounds = contentRef.getBoundingClientRect();

        const space = 8;

        const leftOffset = anchorBounds.x - contentBounds.width + anchorBounds.width;

        const position = {
            center: anchorBounds.x + anchorBounds.width / 2 - contentBounds.width / 2,
            bottom: anchorBounds.bottom + space,
            top: anchorBounds.top - contentBounds.height - space,
            left: leftOffset < 0 ? space : leftOffset,
            right: anchorBounds.x,
        };

        if (vertical === "bottom" && anchorBounds.bottom + space + contentBounds.height > window.innerHeight) {
            position.bottom = position.top;
        }

        if (vertical === "top" && anchorBounds.top + space + contentBounds.height < 0) {
            position.top = position.bottom;
        }

        setPosition(position);
    }, [vertical, anchorRef, contentRef, isOpen]);

    useEffect(calculatePosition, [calculatePosition]);

    useEffect(() => {
        global.addEventListener("resize", calculatePosition);
        return () => global.removeEventListener("resize", calculatePosition);
    }, [calculatePosition]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        if (!isOpen) return;
        setIsOpen(false);
        onClose?.();
    };

    const handleContentClick = isAutoClosable ? handleClose : void 0;

    const handleClick = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, []);

    useClickOutside({
        ref: { current: contentRef },
        callback: handleClose,
        capture: true,
    });

    const style = {
        transform: `translate(${position[horizontal]}px, ${position[vertical]}px)`,
    };

    return {
        style,
        isOpen,
        setAnchorRef,
        setContentRef,
        handleClick,
        handleContentClick,
    };
};
