import styled, { css } from "styled-components";
import { lightTheme } from "@megapolis/uikit/theme";
import { Slider as UiKitSlider } from "@megapolis/uikit/slider";
import { PositionScale } from "./slider.types";

export const SliderContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
    overflow: hidden;
    width: 100%;
`;

export const SliderWrapper = styled.div<{ isInputHide: boolean, positionScale: PositionScale}>(({ isInputHide, positionScale }) => {
    const position = positionScale === PositionScale.top && css`> span {
        > :first-child {

            > :first-child {
                pointer-events: none;
                top: 2px;

                > span {

                    > span {
                        top: -14px;
                    }

                    ::before {
                        opacity: 0;
                    }

                    ::after {
                        content: "";
                        position: absolute;
                        left: -1px;
                        top: 0;
                        width: 2px;
                        height: 4px;
                        border-radius: 4px;
                        background: rgba(3, 40, 76, 0.2);
                    }
                }
            }
        }
    }`
    const inputHide = isInputHide &&  css`> :last-child {
        > :last-child {
            display: none;
        }
    }`;

    return css`
        width: 100%;
        ${position}
        ${inputHide}
    `;
});

export const ScaleLastCaptionWrapper = styled.span`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin: 2px 16px 0;

    > * {
        flex-basis: 100%;
        height: 4px;
        position: relative;
      }
`;

export const ScaleLastCaption = styled.span`
    position: absolute;
    color: ${lightTheme.colors.content.secondary};
    text-align: right;
    margin-top: -2px;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
`;

export const Slider = styled(UiKitSlider)`
     > :first-child {
        margin-top: 10px;
    }
`;