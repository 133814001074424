export interface IBounds {
    includesPoint(x: number, y: number): boolean;
}

export class BoundingBox implements IBounds {
    constructor(public x1: number, public x2: number, public y1: number, public y2: number) {}

    public includesPoint(x: number, y: number) {
        return x >= this.x1 && x <= this.x1 + this.x2 && y >= this.y1 && y <= this.y1 + this.y2;
    }
}

/** Ограничивающий прямоугольник, параллельный осям */
export class RectBBox implements IBounds {
    public x: number;
    public y: number;
    public width: number;
    public height: number;

    /**
     * @param x - x левой верхней точки
     * @param y - y левой верхней точки
     * @param width - полная ширина прямоугольника
     * @param height - полная высота прямоугольника
     */
    constructor(x: number, y: number, width: number, height: number) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    public get left() {
        return this.x;
    }

    public get right() {
        return this.x + this.width;
    }

    public get top() {
        return this.y;
    }

    public get bottom() {
        return this.y + this.height;
    }

    public includesPoint(x: number, y: number) {
        return x >= this.x && x <= this.right && y >= this.y && y <= this.bottom;
    }

    public getBounds() {
        return new BoundingBox(this.left, this.right, this.top, this.bottom);
    }
}
