import { action, computed, makeObservable, observable } from "mobx";
import { BooleanProperty } from "lib";
import { TrafficLightDomain } from "app-domain";

type CycleData = {
    time: number;
    phases: TrafficLightDomain.CyclePhase[];
};

type EditorData = {
    baseCycle: CycleData;
    modifiedCycle: CycleData;
};

export class CycleEditorState {
    public cycleBase?: TrafficLightDomain.CustomCycle;
    public editorData: Nullable<EditorData> = null;
    public selectedPhaseNumber: NullableNumber = null;
    public areDirectionsVisible = new BooleanProperty(false);

    constructor() {
        makeObservable(this, {
            cycleBase: observable.ref,
            hasChanges: computed,
            updateCycleData: action.bound,
            reset: action.bound,
            selectCycle: action,
            selectedCycleId: computed,
            editorData: observable,
            time: computed,
            setTime: action,
            selectedPhaseNumber: observable,
            updatePhases: action,
        });
    }

    public selectCycle(cycle?: TrafficLightDomain.CustomCycle | null) {
        if (!cycle || cycle.time === 0) return;
        const cycleData: CycleData = {
            time: cycle.time,
            phases: cycle.phases ?? [],
        };
        this.editorData = {
            baseCycle: cycleData,
            modifiedCycle: cycleData,
        };
        this.cycleBase = cycle;
    }

    public get selectedCycleId() {
        return this.cycleBase?.id ?? null;
    }

    public get time() {
        return this.editorData?.modifiedCycle.time ?? 0;
    }

    public get hasChanges() {
        return this._checkCycleTimeChanges() || this._checkPhaseChanges();
    }

    public get newCycle(): TrafficLightDomain.INewCustomCycle | null {
        if (!this.cycleBase) return null;
        return {
            name: this.cycleBase.name,
            userDisplayName: this.cycleBase.userDisplayName,
            userId: this.cycleBase.userId,
            time: this.time,
            phases: (this.editorData ? this.editorData.modifiedCycle.phases : this.cycleBase.phases) ?? [],
        };
    }

    public updatePhases(phases: TrafficLightDomain.CyclePhase[]) {
        if (!this.editorData) return;
        this.editorData.modifiedCycle.phases = phases;
    }

    public setTime(value: number) {
        if (!this.editorData) return;
        this.updateCycleData({
            time: value,
        });
    }

    public updateCycleData = (data: Partial<CycleData>) => {
        if (!this.editorData) return;
        this.editorData.modifiedCycle = {
            ...this.editorData.modifiedCycle,
            ...data,
        };
    };

    public reset = () => {
        if (!this.editorData) return;
        this.editorData = {
            baseCycle: this.editorData.baseCycle,
            modifiedCycle: {
                time: this.editorData.baseCycle.time,
                phases: this.editorData.baseCycle.phases.slice(),
            },
        };
    };

    private _checkCycleTimeChanges() {
        if (!this.editorData) return false;
        return this.editorData.baseCycle.time !== this.editorData.modifiedCycle.time;
    }

    private _checkPhaseChanges() {
        if (!this.editorData) return false;
        const { modifiedCycle, baseCycle } = this.editorData;
        return baseCycle.phases.some((basePhase, index) => modifiedCycle.phases[index].tPhase !== basePhase.tPhase);
    }
}
