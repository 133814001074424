import { CooGroupDomain } from "app-domain";
import { APITypes } from "api";

export class CooGroupCycleMapper {
    public fromAPI(apiCycle: APITypes.CooGroupCycle): CooGroupDomain.Cycle {
        return {
            ...apiCycle,
            createdDate: new Date(apiCycle.createdDate),
            cycles: apiCycle.cycles?.filter(Boolean).map((cycle) => {
                return {
                    ...cycle,
                    syncDate: new Date(cycle.syncDate),
                    vDate: cycle.vDate ? new Date(cycle.vDate) : void 0,
                    phases: cycle.phases?.filter(Boolean) ?? [],
                };
            }),
        };
    }

    public listFromAPI(apiCycles: APITypes.CooGroupCycle[]): CooGroupDomain.Cycle[] {
        return apiCycles.map(this.fromAPI.bind(this));
    }

    public toCycleInput(cycleData: CooGroupDomain.Cycle | CooGroupDomain.CustomCycle): APITypes.CooGroupCycleInput {
        return {
            ...cycleData,
            createdDate: cycleData.createdDate.toISOString(),
            cycles: cycleData.cycles?.map((cycle) => ({ ...cycle, syncDate: cycle.syncDate.toISOString() })),
        };
    }
}
