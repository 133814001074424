import { TrafficLightDomain } from "app-domain";
import { speedInMPS } from "shared/constants";
import { CyclePolygonFactory } from "../cycle-polygon-factory";
import { DrawOptions, PhaseView } from "../phase-view";
import { drawParams } from "../editor.constants";
import { getPointerIconParams, phaseMapper } from "./cycle-view.utils";
import { Cycle, GreenWaveRange } from "../editor.types";
import { CycleViewOptions, CreatePolygonParams, InitialPhase, CycleEmptyPart } from "./cycle-view.types";

export class CycleView {
    /** Идентификатор цикла */
    public id: number;
    /** Координаты по y текущего цикла */
    public y: number = 0;
    /** Признак является ли цикл первым */
    public isFirstDirection: boolean = false;
    /** Признак является ли цикл последним */
    public isLastDirection: boolean = false;
    /** Единица измерения длинны в секундах */
    public pixelsPerSecond: number = 0;
    /** Фазы цикла */
    public phases: PhaseView[] = [];
    /** Время прибытия от предыдущего до текущего светофора (distance/speed) */
    public prevTimeByDistance: number = 0;
    /** Время прибытия от текущего до следующего светофора (distance/speed) */
    public nextTimeByDistance: number = 0;
    /** Номер цикла */
    public directionNumber: number = 0;
    /** Тип цикла */
    public directionType?: TrafficLightDomain.Enums.DirectionTypeCode;
    /** Номер цикла */
    public cycleNumber: number;
    /** Признак есть ли у цикла обратное направление */
    public hasBackwardDirection: boolean;
    /** Скорость на промежутке между предыдущим и текущим светофорами в прямом направлении */
    public directSpeed: number = 0;
    /** Скорость на промежутке между предыдущим и текущим светофорами в обратном направлении */
    public reverseSpeed: number = 0;
    /** Расстояние от первого светофора */
    public distanceFromStart: number = 0;
    /** Отрисовка цикла происходит кратно, это количество для повторной отрисовки */
    public visibleCycleRepeatCount: number = 0;
    /** Длительность цикла в секундах (редактируемое поле) */
    public cycleDurationTime: number = 0;
    /** Минимальная длительность цикла в секундах (tMin + tProm) */
    public cycleMinDurationTime: number = 0;
    /** Длительность цикла в секундах (не редактируемое поле) */
    public originCycleDurationTime: number = 0;
    /** Время сдвига цикла в секундах */
    public shift: number = 0;
    /** Координаты по y предыдущего цикла */
    public prevDirectionY: number = 0;
    /** Координаты по y следующего цикла */
    public nextDirectionY: number = 0;
    /** Координаты по y для отрисовки скорости и расстояния */
    public speedDistanceLegendY: number = 0;
    /** номер направления */
    public cycleDirectionLabel: string = "";
    /** Диапазоны разрешенных направлений */
    public greenWaveRange: GreenWaveRange[] = [];
    /** Индекс кратного цикла попавшего под drag */
    public visibleCycleRepeatDragIndex?: number;
    /** Светофоры */
    public trafficLight: TrafficLightDomain.TrafficLight;
    public cycleEmptyParts: CycleEmptyPart[] = [];
    public overlays: { x: number; width: number; y: number }[] = [];
    /** Расстояние участка маршрута */
    public segmentDistance: number;
    /** Циклы с первоначальными значениями */
    private initialCycles: Cycle[] = [];

    constructor(private options: CycleViewOptions) {
        const cycle = options.cycle;
        this.isFirstDirection = options.index === 0;
        this.isLastDirection = options.index === options.cyclesList.length - 1;
        this.y = options.y;
        this.initialCycles = options.initialCycles;
        this.pixelsPerSecond = options.pixelsPerSecond;
        this.hasBackwardDirection = options.hasBackwardDirection;
        this.id = cycle.id;
        this.cycleNumber = cycle.number;
        this.visibleCycleRepeatCount = options.visibleCycleRepeatCount;
        this.directSpeed = cycle.directSpeed;
        this.reverseSpeed = cycle.reverseSpeed;
        this.distanceFromStart = cycle.distance;
        this.segmentDistance = options.segmentDistance;
        this.cycleDurationTime = cycle.time;
        this.originCycleDurationTime = options.initialCycles[options.index].time;
        this.shift = cycle.shift;
        this.directionNumber = cycle.directionNumber;
        this.trafficLight = cycle.trafficLight;
        this.directionType = cycle.trafficLight.getDirectionByNum(cycle.directionNumber)?.type;
        this.cycleDirectionLabel = `${this.directionNumber}Н`;

        this.setTimeAndCoordinates({
            cycle,
            index: options.index,
            cyclesList: options.cyclesList,
            prevCycle: options.prevCycle,
        });
        this.setPhases(cycle.phases);
        this.setCycleMinDurationTime();
        this.setGreenWaveRange();
        this.setOverlay();
    }

    public get isCycleTimeChanged() {
        return this.cycleDurationTime !== this.originCycleDurationTime;
    }

    public get isGreaterThan() {
        return this.cycleDurationTime < this.originCycleDurationTime;
    }

    public get isSmallThan() {
        return this.cycleDurationTime > this.originCycleDurationTime;
    }

    /** Заголовок цикла */
    public getCycleHeadLabel(isEditMode: boolean) {
        return `СО ${this.options.cycle.trafficLight.num}${
            !isEditMode && this.options.cycle.time ? ` \u{2022} ${this.options.cycle.time} с` : ""
        }`;
    }

    public createPolygons = (params: CreatePolygonParams) => {
        const { cycleIndex, cyclesList } = params;
        for (let index = 0; index < this.phases.length; index++) {
            const phase = this.phases[index];
            if (phase.allow) {
                phase.polygons = new CyclePolygonFactory({
                    phase,
                    currentCycle: cyclesList[cycleIndex],
                    width: params.canvasWidth,
                    nextCycle: cyclesList[cycleIndex + 1],
                    prevCycle: cyclesList[cycleIndex - 1],
                    nextPhase: this.phases[index + 1] ?? this.phases[0],
                    nextTimeByDistance: this.nextTimeByDistance,
                    prevTimeByDistance: this.prevTimeByDistance,
                    pixelsPerSecond: this.pixelsPerSecond,
                    hasBackwardDirection: this.hasBackwardDirection,
                }).build();
            }
        }
    };

    private setCycleMinDurationTime() {
        this.cycleMinDurationTime =
            this.options.cycle.phases?.reduce((time, phase) => time + phase.tMin + phase.tProm, 0) ?? 0;
    }

    private setOverlay = () => {
        if (this.isSmallThan) return;

        const count = Math.floor(this.options.totalViewTime / this.cycleDurationTime);
        const width = (this.cycleDurationTime - this.originCycleDurationTime) * this.pixelsPerSecond;

        for (let i = 0; i < count; i++) {
            const x = (this.shift + this.originCycleDurationTime + i * this.cycleDurationTime) * this.pixelsPerSecond;

            if (this.hasBackwardDirection) {
                this.overlays.push({
                    x,
                    width,
                    y: this.y + 24,
                });
            }
            this.overlays.push({
                x,
                width,
                y: this.y,
            });
        }
    };

    private setTimeAndCoordinates = (params: {
        index: number;
        cycle: Cycle;
        cyclesList: Cycle[];
        prevCycle?: CycleView;
    }) => {
        const { index, cycle, cyclesList, prevCycle } = params;
        const nextCycle = cyclesList[index + 1];

        /** prevDist: Дистанция в метрах от предыдущего светофора до текущего */
        const prevDist = this.isFirstDirection ? 0 : cycle.distance - cyclesList[index - 1].distance;
        /** nextDist: Дистанция в метрах от текущего светофора до следущего */
        const nextDist = this.isLastDirection ? 0 : nextCycle.distance - cycle.distance;
        /** prevDist: Скорость на предыдуей дистанции */
        const prevSpeed = (this.isFirstDirection ? cycle.reverseSpeed : prevCycle?.reverseSpeed ?? 0) / speedInMPS;
        /** prevDist: Скорость на следущей дистанции */
        const nextSpeed = (this.isLastDirection ? cycle.directSpeed : nextCycle.directSpeed) / speedInMPS;

        this.prevTimeByDistance = Math.round((this.isFirstDirection ? cycle.distance : prevDist) / prevSpeed);
        this.nextTimeByDistance = Math.round(nextDist / nextSpeed);

        if (!this.isFirstDirection && prevCycle) {
            const prevDirectionBackwardHeight = prevCycle?.hasBackwardDirection
                ? drawParams.directionBarHeight + drawParams.backwardDirectionGap
                : 0;

            this.prevDirectionY = prevCycle.y + drawParams.directionBarHeight + prevDirectionBackwardHeight;
        }

        if (!this.isLastDirection) {
            const backwardDirectionHeight = this.hasBackwardDirection
                ? drawParams.directionBarHeight + drawParams.backwardDirectionGap
                : 0;
            const currentCycleTopY = this.y;
            const currentCycleBottomY = currentCycleTopY + drawParams.directionBarHeight + backwardDirectionHeight;

            this.nextDirectionY =
                currentCycleBottomY + Math.max(drawParams.cycleMinDistance, nextDist * drawParams.pixelPerMeter);
        }

        const difference = this.y - this.prevDirectionY + drawParams.cycleHeadHeight;

        this.speedDistanceLegendY = this.y - difference / 2;
    };

    private setGreenWaveRange = () => {
        this.greenWaveRange = this.phases.reduce((acc: GreenWaveRange[], phase, index, list) => {
            const nextPhase = list[index + 1] ?? list[0];

            if (!phase.direction || !nextPhase.direction) return acc;

            const isSameDirection = phase.allow === phase.nextPhase?.allow;
            const tProm = phase.tPhase - phase.tBasic;
            const rangeStart = phase.drawOptions.phaseShiftFromStart;
            const rangeEnd = rangeStart + (phase.tBasic + (isSameDirection ? tProm : phase.direction.tGreenBlink));

            acc.push({
                allow: phase.allow,
                range: [rangeStart, rangeEnd],
            });
            return acc;
        }, []);
    };

    private setPhases = (phases: Cycle["phases"]) => {
        const initialCycle = this.initialCycles.find((item) => item.id === this.id);
        if (!initialCycle?.phases || !phases) return;

        const initialPhases: InitialPhase[] = [];
        const drawPhases: PhaseView[] = [];

        for (let index = 0; index < initialCycle.phases.length; index++) {
            const prev = initialPhases[index - 1];
            const initialCyclePhase = initialCycle.phases[index];
            const phaseShiftFromStart = index > 0 ? prev.phaseShiftFromStart + prev.tPhase : this.shift;
            const initialPhase = {
                phaseShiftFromStart,
                tPhase: initialCyclePhase.tPhase,
            };

            initialPhases.push(initialPhase);
            drawPhases.push(
                phaseMapper({
                    index,
                    drawPhases,
                    initialPhase,
                    trafficLight: this.trafficLight,
                    pixelsPerSecond: this.pixelsPerSecond,
                    directionNumber: this.directionNumber,
                    phaseList: phases,
                })
            );
        }

        for (let time = 0, currentPhase = drawPhases[0]; time < this.options.totalViewTime * 2; ) {
            if (!currentPhase.nextPhase) return;
            const phaseShiftFromStart = this.shift + time;
            const cycleIndex = Math.floor(time / this.cycleDurationTime);
            const phase = Object.assign({}, currentPhase);

            phase.dragOptions = {
                duplicateIndex: this.phases.filter((item) => item.phaseNumber === phase.phaseNumber).length,
            };

            phase.drawOptions = new DrawOptions({
                phaseShiftFromStart,
                pixelsPerSecond: this.pixelsPerSecond,
                tPhase: currentPhase.tPhase,
                tBasic: currentPhase.tBasic,
                phaseNumber: currentPhase.phaseNumber,
                allow: currentPhase.allow,
            });

            phase.initialPhase = {
                phaseShiftFromStart: phase.initialPhase.phaseShiftFromStart + cycleIndex * this.cycleDurationTime,
                tPhase: phase.initialPhase.tPhase,
            };

            phase.pointerIconParams = getPointerIconParams({
                initialPhase: phase.initialPhase,
                trafficLight: this.trafficLight,
                directionNumber: this.directionNumber,
                phaseShiftFromStart,
                tPhase: currentPhase.tPhase,
                currentPhaseNumber: currentPhase.phaseNumber,
                nextPhaseNumber: currentPhase.nextPhase.phaseNumber,
            });

            this.phases.push(phase);

            time += currentPhase.tPhase;

            if (this.isCycleTimeChanged) {
                const isLastPhase = currentPhase === drawPhases[drawPhases.length - 1];
                if (isLastPhase) {
                    if (this.cycleDurationTime > this.originCycleDurationTime) {
                        this.cycleEmptyParts.push({
                            fillStyle: "silver",
                            y: this.y,
                            startX: time * this.pixelsPerSecond,
                            endX: time + (this.cycleDurationTime - this.originCycleDurationTime),
                        });

                        time += this.cycleDurationTime - this.originCycleDurationTime;
                    }
                }
            }
            currentPhase = currentPhase.nextPhase;
        }

        for (
            let time = 0, currentPhase = drawPhases[drawPhases.length - 1];
            time < this.shift + this.options.totalViewTime;

        ) {
            if (!currentPhase.prevPhase || !currentPhase.nextPhase) return;
            const phaseShiftFromStart =
                this.shift - time - currentPhase.tPhase - (this.cycleDurationTime - this.originCycleDurationTime);
            const cycleIndex = Math.floor((-time - currentPhase.tPhase) / this.cycleDurationTime);
            const phase = Object.assign({}, currentPhase);

            phase.drawOptions = new DrawOptions({
                phaseShiftFromStart,
                pixelsPerSecond: this.pixelsPerSecond,
                tPhase: currentPhase.tPhase,
                tBasic: currentPhase.tBasic,
                phaseNumber: currentPhase.phaseNumber,
                allow: currentPhase.allow,
            });

            phase.initialPhase = {
                phaseShiftFromStart: phase.initialPhase.phaseShiftFromStart + cycleIndex * this.cycleDurationTime,
                tPhase: phase.initialPhase.tPhase,
            };

            phase.pointerIconParams = getPointerIconParams({
                phaseShiftFromStart,
                initialPhase: phase.initialPhase,
                trafficLight: this.trafficLight,
                directionNumber: this.directionNumber,
                tPhase: currentPhase.tPhase,
                currentPhaseNumber: currentPhase.phaseNumber,
                nextPhaseNumber: currentPhase.nextPhase.phaseNumber,
            });

            if (!time) {
                phase.nextPhase = this.phases[this.phases.length - 1];
            }

            this.phases.unshift(phase);

            time += currentPhase.tPhase;
            currentPhase = currentPhase.prevPhase;
        }
    };
}
