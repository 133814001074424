import { memo, MouseEvent, useCallback, useMemo } from "react";
import { Circle } from "../circle";
import { WrappedChip } from "./filter-chip.styles";

export type FilterChipData = {
    name: string;
    code: number | string;
    isActive: boolean;
    color?: string;
    count?: number;
};

export type ChipClickHandler = (value: { code: number | string; event: MouseEvent<HTMLButtonElement> }) => void;

export type FilterChipProps = FilterChipData & {
    onClick: ChipClickHandler;
};

const FilterChipComponent = (props: FilterChipProps) => {
    const { onClick, name, color, count, isActive, code } = props;

    const icon = useMemo(() => (color ? <Circle color={color} /> : null), [color]);

    const caption = useMemo(() => (typeof count === "number" ? String(count) : ""), [count]);

    const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => onClick({ code, event }), [
        code,
        onClick,
    ]);

    return <WrappedChip label={name} icon={icon} caption={caption} isActive={isActive} onClick={handleClick} />;
};

export const FilterChip = memo(FilterChipComponent);
