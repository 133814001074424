import { CooGroupDomain } from "app-domain";

export class CustomCycleFactory {
    create(cooGroupId: number, type: CooGroupDomain.CycleType): CooGroupDomain.CustomCycle {
        return {
            name: "",
            cycles: [],
            isEnabled: false,
            schedules: [],
            greenLinePhases: [],
            cooGroupId,
            type,
            purpose: CooGroupDomain.PurposeType.Base,
            createdDate: new Date(),
            isActiveNow: false,
        };
    }
}
