import { Types } from "shared";
import { FacilityDetectorTrigger, FacilityDetectorState } from "../types";

export type FacilityLogsFilter = {
    start?: string;
    finish?: string;
    eventTypes?: number[];
    modes?: string[];
    controlModes?: number[];
    statuses?: number[];
    malfunctionTypes?: number[];
};

export type PaginationOptions = {
    page?: number;
    count?: number;
};

export type GetFacilityLogsArgs = {
    facilityId: string | number;
    filter?: FacilityLogsFilter;
    includeMalfunctionDetails?: boolean;
    includeRaw?: boolean;
} & PaginationOptions;

export type GetFacilityHistorySnapshotArgs = {
    facilityId: string | number;
    filter?: FacilityLogsFilter;
};

export type GetFacilitiesOptions = {
    search?: string;
    controlModes?: number[];
    conditions?: number[];
    statuses?: number[];
};

export type GetFacilityIssuesOptions = {
    facilityId: string | number;
    isActive: boolean;
};

export type FacilityIssuesResponseData = {
    id: number;
    address: string;
    assetNum: string;
    dateTimeStr: string;
    departmentId: number;
    descr: string;
    issueName: string;
    num: number;
    priority: number;
    registered: string;
    status: number;
    statusName: string;
    departmentName: string;
    breakageTypeName: string;
    statusResolved: string;
    typeId: number;
};

export interface GetDetectorLogsResponseData {
    states: Types.IPagedCollection<FacilityDetectorState>;
    triggers: Types.IPagedCollection<FacilityDetectorTrigger>;
}

export type GetFacilitiesAnalyticsArgs  = {
    facilityId: string | number;
    date: string
};

export type TrafficLightAnalyticsInfo = {
    percent: number,
    phase?: number,
    seconds: number,
    status?: number,
    type: "phase" | "status"
};

export type TrafficLightAnalytics = {
    /** Дата */
    period: number;
    /** Аналитическая информация */
    info: TrafficLightAnalyticsInfo[];
};

export type TrafficLightAnalyticsError = {
    /** Ошибка */
    error: string;
};

export enum AnalyticsSplit {
    phases5m = "phases5m",
    phases15m = "phases15m",
    phases30m = "phases30m",
    phasesDay = "phasesDay",
    phasesHour = "phasesHour",
}

export type AnalyticsBySplit = Record<AnalyticsSplit, TrafficLightAnalytics[]>;

export type PromiseResponse = AnalyticsBySplit | TrafficLightAnalyticsError | null;