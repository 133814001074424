import styled from "styled-components/macro";

export const Panel = styled.div`
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: inherit;
`;

export const Header = styled.div`
    width: 100%;
`;

export const List = styled.div`
    flex-grow: 1;
    width: 100%;
`;
