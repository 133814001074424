import { CodeListFilter } from "lib";
import { makeAutoObservable } from "mobx";

import { Enums } from "app-domain/traffic-light";
export class Filter {
    public readonly conditions = new CodeListFilter<Enums.ConditionType>();
    public readonly controlModes = new CodeListFilter<Enums.ControlModeCode>();
    private _search: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    public get shape() {
        return {
            conditions: this.conditions.list,
            controlModes: this.controlModes.list,
            search: this.search,
        };
    }
    
    public get search() {
        return this._search;
    }

    public set search(value: string) {
        this._search = value;
    }
}
