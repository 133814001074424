import { defaultFont, makePath2DDrawer } from "./drawing.common";
import { RectLikeProps } from "./drawing.types";
import { drawRect, drawGridColumns } from "./common";

export type DrawCycleDirectionData = Partial<{
    isAllowed: boolean;
    tGreenBlink: number;
    tYellow: number;
    tRed: number;
    tRedYellow: number;
    tDelay: number;
}>;

export interface DrawCycleDirectionProps extends RectLikeProps {
    pxPerSecond: number;
    data: DrawCycleDirectionData;
    grid?: {
        lineColor?: string;
        lineWidth?: number;
    };
    text?: string;
    img?: HTMLImageElement;
}

const colors = {
    greenBlink: "#1E7735",
    green: "#32C758",
    red: "#FF3B30",
    yellow: "#FECB00",
    gray: "rgba(0,0,0, 0.18)",
};

export const drawCycleDirection = makePath2DDrawer<DrawCycleDirectionProps>((ctx, props) => {
    const { x, y, width, height, pxPerSecond, grid, img, text, data } = props;
    let textX = x;
    let textY = y + height * 0.5;
    const path = drawRect(ctx, {
        x,
        y,
        width,
        height,
        fillColor: data.isAllowed ? colors.green : colors.red,
    });
    drawBlinks(ctx, {
        x: x + width,
        y,
        width,
        height,
        data,
        pxPerSecond,
    });
    drawGridColumns(ctx, {
        x,
        y,
        width,
        height,
        lineColor: grid?.lineColor,
        lineWidth: grid?.lineWidth,
        step: pxPerSecond,
    });
    if (img) {
        let imgY = y + height * 0.5 - img.height * 0.5;
        textX = x + img.width;
        ctx.drawImage(img, x, imgY);
    }
    if (!text) return path;
    ctx.textBaseline = "middle";
    ctx.font = defaultFont;
    ctx.fillText(text, textX, textY);
    return path;
});

const drawBlinks = (ctx: CanvasRenderingContext2D, props: DrawCycleDirectionProps) => {
    const {
        y,
        height,
        pxPerSecond,
        data: { isAllowed, tRed, tYellow, tGreenBlink, tRedYellow, tDelay = 0 },
    } = props;
    const halfSecondWidth = pxPerSecond / 2;
    const halfBarHeight = height / 2;
    ctx.save();

    if (isAllowed) {
        let x = props.x;
        if (tRed) {
            ctx.fillStyle = colors.red;
            x -= (tRed + tDelay) * pxPerSecond;
            const width = pxPerSecond * tRed;
            ctx.fillRect(x, y, width, height);
        }

        if (tYellow) {
            ctx.fillStyle = colors.yellow;

            x -= tYellow * pxPerSecond;

            const yellowWidth = pxPerSecond * tYellow;
            ctx.fillRect(x, y, yellowWidth, height);
        }

        if (tGreenBlink) {
            ctx.fillStyle = colors.greenBlink;
            for (let i = 1; i <= tGreenBlink; i++) {
                x -= pxPerSecond;
                ctx.fillRect(x + halfSecondWidth, y, halfSecondWidth, height);
            }
        }
    } else {
        // Отрисовка бликов во время красного света
        if (tRedYellow) {
            ctx.fillStyle = colors.yellow;
            for (let i = 1; i <= tRedYellow; i++) {
                const x = props.x - i * pxPerSecond + halfSecondWidth;
                ctx.fillRect(x, y + halfBarHeight, halfSecondWidth, halfBarHeight);
            }
        }
    }
    ctx.restore();
};
