import format from "date-fns/format";
import { Detector } from "app-domain";
import { detector } from "shared/constants";
import { ChartOptions, ChartDataset } from "chart.js";
import { BooleanStorageManager } from "lib";
import { v4 } from "uuid";

type Indicator = Pick<Detector.Models.FullCard.Indicator, "uid" | "caption" | "units" | "valuesByFilter"> & {
    statistics: {
        labels: string[];
        average: ChartDataset;
        datasets: ChartDataset[];
    };
};

export class StatisticsDTO {
    private static keys = ["speed", "volume", "utilization", "occ"] as const;
    private static indicatorFactory = (source: Detector.Models.Indicator, lanes: number[]): Indicator => ({
        uid: source.uid,
        caption: source.caption,
        units: source.units,
        valuesByFilter: [],
        statistics: {
            labels: [],
            average: {
                label: `Средняя`,
                data: [],
                borderColor: source.color,
                pointRadius: 0,
                pointHitRadius: 10,
                pointHoverRadius: 2,
                pointBackgroundColor: source.color,
                borderWidth: 2,
                clip: { left: 3, top: 3, right: 3, bottom: 3 },
            },
            datasets: lanes.reduce((acc: ChartDataset[], lane) => {
                acc[lane] = {
                    label: `${lane} полоса`,
                    data: [],
                    borderColor: source.secondaryColor,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    pointHoverRadius: 2,
                    pointBackgroundColor: source.secondaryColor,
                    borderWidth: 2,
                    clip: { left: 3, top: 3, right: 3, bottom: 3 },
                };

                return acc;
            }, []),
        },
    });

    /** Скорость */
    private speed: Indicator;
    /** Интенсивность */
    private volume: Indicator;
    /** Загруженность */
    private utilization: Indicator;
    /** Занятость */
    private occ: Indicator;

    constructor(lanes: number[], data?: any) {
        this.speed = StatisticsDTO.indicatorFactory(detector.indicators.speed, lanes);
        this.volume = StatisticsDTO.indicatorFactory(detector.indicators.volume, lanes);
        this.utilization = StatisticsDTO.indicatorFactory(detector.indicators.utilization, lanes);
        this.occ = StatisticsDTO.indicatorFactory(detector.indicators.occ, lanes);

        if (typeof data !== "object" || !Array.isArray(data)) return;

        const handler = this.addItem(lanes);
        data.forEach(handler);
    }

    private addItem = (lanes: number[]) => (item: any, index: number) => {
        const timestampValue = new Date(item.timestamp).valueOf();
        if (!timestampValue) return;
        const label = format(new Date(item.timestamp), "HH:mm");

        StatisticsDTO.keys.forEach((key) => {
            const field = this[key];
            if (!field) return;

            field.statistics.labels[index] = label;

            lanes.forEach((lineNumber: number) => {
                // данные по полосе
                const dataset = field.statistics.datasets[lineNumber];
                const average = field.statistics.average;

                if (!dataset || !average) return;

                if (typeof item[key] === "object" && Array.isArray(item[key])) {
                    dataset.data[index] = item[key]?.[lineNumber - 1] ?? 0;
                    average.data[index] = this.getAverageByKey(item, key) ?? 0;
                } else {
                    dataset.data[index] = 0;
                    average.data[index] = 0;
                }
            });
        });
    };

    private getAverageByKey = (item: any, key: "speed" | "volume" | "utilization" | "occ") => {
        switch (key) {
            case "speed":
                return item?.["averageSpeed"] ?? 0;
            case "volume":
                return item?.["averageVolume"] ?? 0;
            case "utilization":
                return item?.["averageUtilization"] ?? 0;
            case "occ":
                return item?.["averageOcc"] ?? 0;
            default:
                return 0;
        }
    };

    private optionsFactory(labels: string[]): ChartOptions {
        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                },
            },
            animation: {
                duration: 0,
            },
            scales: {
                x: {
                    ticks: {
                        callback: function (_, index) {
                            const value = labels?.[index];
                            if (value?.endsWith?.(":00")) return value;
                            return undefined;
                        },
                    },
                },
                y: {
                    position: "left",
                    suggestedMax: 120,
                    beginAtZero: true,
                    ticks: {
                        maxTicksLimit: 7,
                        color: "#cccccc",
                        font: {
                            size: 7,
                        },
                    },
                },
            },
        };
    }

    get collections(): Detector.Models.FullCard.Indicator[] {
        return StatisticsDTO.keys.reduce((acc: Detector.Models.FullCard.Indicator[], key) => {
            const field = this[key];
            if (field) {
                const booleanStorageManager = new BooleanStorageManager(
                    `megapolis__asudd__detector-full-card__indicator-${field.uid}_is-expaned`
                );

                const valuesByFilter = field.statistics.datasets.map((dataset) => {
                    let quantity = Number(dataset.data[dataset.data.length - 1] ?? 0);

                    if (key === "utilization" || key === "occ") {
                        quantity = Math.round(quantity * 100) / 100;
                    }

                    return {
                        uid: v4(),
                        caption: dataset.label ?? "полоса",
                        color: String(dataset.borderColor ?? "#000000"),
                        quantity: String(quantity),
                    };
                });

                valuesByFilter[0] = {
                    uid: v4(),
                    caption: field.statistics.average.label ?? "Итого",
                    color: (detector.indicators as any)[key]?.color ?? "#000000",
                    quantity: String(field.statistics.average.data[field.statistics.average.data.length - 1] ?? 0),
                };

                acc.push({
                    uid: field.uid,
                    caption: field.caption,
                    units: field.units,
                    chartConfiguration: {
                        type: "line",
                        options: this.optionsFactory(field.statistics.labels),
                        data: {
                            labels: field.statistics.labels,
                            datasets: [field.statistics.average].concat(field.statistics.datasets.filter((el) => !!el)),
                        },
                    },
                    valuesByFilter: valuesByFilter.filter((el) => !!el),
                    getExpanedState: () => booleanStorageManager.value,
                    setExpanedState: (flag: boolean) => {
                        booleanStorageManager.value = flag;
                    },
                });
            }

            return acc;
        }, []);
    }
}
