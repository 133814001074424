import { makeAutoObservable, reaction, runInAction } from "mobx";
import throttle from "lodash/throttle";
import { CooGroupDomain } from "app-domain";
import { CooGroup } from "services";
import { Selection } from "../selection";
import { Filter } from "./filter";
import { SearchResult } from "./coo-groups.types";

export class CooGroupStore {
    public isLoading: boolean = true;
    public deletableCooGroupId: NullableNumber = null;
    public readonly filter = new Filter();
    private _items: Map<number, CooGroupDomain.CooGroup> = new Map();
    private _filteredIds: Set<number> = new Set();
    private selectedItems = new Selection();
    private filterChangeEffect: VoidFunction;
    /**
     * Дополнительные сущности, попавшие под результаты поиска(Циклы и т.п)
     * сгруппированы по Id ленты координации
     */
    private _searchResults: Map<number, SearchResult> | null = null;

    constructor(private service: CooGroup.CooGroupService) {
        makeAutoObservable(this);
        this.filterChangeEffect = reaction(() => this.filter.shape, this.throttledSearch);
    }

    public get hasSearchResults() {
        return !!this._searchResults;
    }

    public get total() {
        return this._items.size;
    }

    public get list() {
        return Array.from(this._items.values());
    }

    public get filteredList() {
        const cooGroup: CooGroupDomain.CooGroup[] = [];
        this._filteredIds.forEach((id) => {
            const item = this._items.get(id);
            if (item) cooGroup.push(item);
        });
        return cooGroup;
    }

    public get firstInSelection() {
        const id = this.selectedItems.first;
        if (!id) return null;
        return this.getById(id);
    }

    public updateCooGroup(cooGroup: CooGroupDomain.CooGroup) {
        this._items.set(cooGroup.id, cooGroup);
    }

    public async loadList() {
        try {
            const list = await this.service.getCooGroupList();
            runInAction(() => {
                this._items = list;
                this._filteredIds = new Set(list.keys());
            });
            await this.updateStates();
        } catch (error) {
            console.error(error);
        }
    }

    public isSelectedItem(id: number) {
        return this.selectedItems.isSelected(id);
    }

    public toggleSelection(id: number) {
        this.selectedItems.toggle(id);
    }

    public chooseItem(id: number) {
        this.selectedItems.clear();
        this.selectedItems.add(id);
    }

    public clearSelectedItems() {
        this.selectedItems.clear();
    }

    public getById(id: number) {
        return this._items.get(id) ?? null;
    }

    public getSearchEntriesByCooGroupId(cooGroupId: number) {
        return this.searchResults?.get(cooGroupId) ?? null;
    }

    public enableCooGroup = async (id: number, value: boolean) => {
        const cooGroup = this.getById(id);
        if (!cooGroup) return;
        await this.service.enableCooGroup(id, value);
        cooGroup.isEnabled = value;
    };

    public async loadDetailsById(id: number) {
        const existing = this.getById(id);
        const data = await this.service.getCooGroupById(id);
        if (!existing) {
            runInAction(() => {
                this._items.set(id, data);
            });
            return data;
        }
        existing.cycles = data.cycles;
        return existing;
    }

    public async updateStates() {
        const stateList = await this.service.getStateList();
        for (const state of stateList) {
            const cooGroup = this.getById(state.id);
            cooGroup?.updateState(state);
        }
    }

    public setCooGroupIdForDelete(id: number) {
        this.deletableCooGroupId = id;
    }

    public applyCooGroupDeletion = async () => {
        if (!this.deletableCooGroupId) return;
        await this.service.deleteCooGroup(this.deletableCooGroupId);

        runInAction(() => {
            if (!this.deletableCooGroupId) return;
            this._items.delete(this.deletableCooGroupId);
            this.deletableCooGroupId = null;
        });
    };

    public cancelCooGroupDeletion = () => {
        this.deletableCooGroupId = null;
    };

    public destroy() {
        this.filterChangeEffect();
    }

    private get searchResults() {
        return this._searchResults;
    }

    private set items(value: Map<number, CooGroupDomain.CooGroup>) {
        this._items = value;
    }

    private set filteredIds(value: Set<number>) {
        this._filteredIds = value;
    }

    private set searchResults(value: Map<number, SearchResult> | null) {
        this._searchResults = value;
    }

    private search = async () => {
        const itemMap = await this.service.getCooGroupList(this.filter.shape);
        const searchResults = new Map();
        const filteredIds = new Set<number>();
        for (let cooGroup of itemMap.values()) {
            searchResults.set(cooGroup.id, {
                cycleIds: cooGroup.cycles.map((cycle) => cycle.id),
            });
            filteredIds.add(cooGroup.id);
        }
        this.searchResults = searchResults;
        this.filteredIds = filteredIds;
    };

    private throttledSearch = throttle(this.search, 500, { leading: false, trailing: true });
}
