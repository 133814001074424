import { memo } from "react";
import styled from "styled-components/macro";
import { FilterChipData, FilterChip, ChipClickHandler } from "./filter-chip";

export type FilterChipsProps = {
    chips: FilterChipData[];
    onClick: ChipClickHandler;
};

const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: ${({ theme }) => theme.spacings[3].str};
`;

const FilterChipsComponent = (props: FilterChipsProps) => (
    <Wrapper>
        {props.chips.map((chip) => (
            <FilterChip key={`filter-chip-${chip.code}-${chip.name}`} {...chip} onClick={props.onClick} />
        ))}
    </Wrapper>
);

export const FilterChips = memo(FilterChipsComponent);
