import { CustomCycle } from "app-domain/traffic-light";
import { CycleView } from "../cycle-view";
import { PhaseView } from "../phase-view";
import { drawParams } from "../editor.constants";
import { HitTestTarget, ChangeHandler, SpeedKind, Cycle } from "../editor.types";
import { Context } from "../context";
import { getCycleY } from "./behavior.utils";

export interface CooGroupCycleDataHandlerParams {
    onChange: ChangeHandler;
    context: Context;
    width: number;
}

export class Behavior {
    private width: number = 0;
    private context: Context;

    constructor(private params: CooGroupCycleDataHandlerParams) {
        this.context = params.context;
        this.width = params.width;
        this.initCooGroupCycle();
    }

    public onChange = () => {
        this.params.onChange(this.context.editableCooGroupCycle);
    };

    public updateRenderingData = (width: number) => {
        this.width = width;
        this.context.view.pixelsPerSecond = width / this.context.totalTime;
    };

    public readonly updateDrawData = (): void => {
        this.setTrafficLightCycles();
        this.createPolygons();
        this.updateCanvasHeight();
    };

    public cycleShiftChange = (x: number, dragState: Nullable<HitTestTarget>) => {
        if (!dragState) return;

        const dragElement = dragState.dragElement as CycleView;
        const cycleIndex = this.context.editableCooGroupCycle.cycles.findIndex((item) => item.id === dragElement.id);

        if (cycleIndex === -1) return;

        const cycle = this.context.editableCooGroupCycle.cycles[cycleIndex];
        const xInSeconds = Math.round(x / this.context.view.pixelsPerSecond);
        const nextXTime = (dragElement.visibleCycleRepeatDragIndex ?? 0) * cycle.time;

        cycle.shift =
            xInSeconds - nextXTime < 0 ? 0 : xInSeconds - nextXTime > cycle.time ? cycle.time : xInSeconds - nextXTime;
    };

    public cyclePhaseSizeChange = (mouseX: number, dragState: Nullable<HitTestTarget>) => {
        if (!dragState || dragState.cycleIndex === undefined || !this.context.editableCooGroupCycle) return;
        const dragElement = dragState.dragElement as [PhaseView, PhaseView];
        const initialCycle = this.context.initialCooGroupCycle.cycles[dragState.cycleIndex];
        const cycle = this.context.editableCooGroupCycle.cycles[dragState.cycleIndex];

        if (!cycle || !cycle.phases || !cycle.trafficLight) return;

        const leftPhase = dragElement[0];
        const rightPhase = dragElement[1];

        const targetTime = Math.round(mouseX / this.context.view.pixelsPerSecond);

        const leftTBasic = targetTime - leftPhase.drawOptions.phaseShiftFromStart - leftPhase.drawOptions.tProm;
        const rightTBasic = rightPhase.tBasic - (targetTime - rightPhase.drawOptions.phaseShiftFromStart);
        const isLeftPhaseTimeValid = leftTBasic >= leftPhase.tMin;
        const isRightPhaseTimeValid = rightTBasic >= rightPhase.tMin;

        const hasCycleTimeDiff = initialCycle.time !== cycle.time;

        if (hasCycleTimeDiff ? !isLeftPhaseTimeValid : !(isLeftPhaseTimeValid && isRightPhaseTimeValid)) return;

        const leftPhaseIndex = cycle.phases.findIndex((phase) => leftPhase.phaseNumber === phase.phaseNumber);
        const rightPhaseIndex = cycle.phases.findIndex((phase) => rightPhase.phaseNumber === phase.phaseNumber);

        if (leftPhaseIndex !== -1) {
            cycle.phases[leftPhaseIndex].tBasic = leftTBasic;
            cycle.phases[leftPhaseIndex].tPhase = leftTBasic + leftPhase.drawOptions.tProm;
        }

        const fillEmptyPartMode = leftPhase.isLast && hasCycleTimeDiff;

        if (!fillEmptyPartMode && rightPhaseIndex !== -1) {
            cycle.phases[rightPhaseIndex].tBasic = rightTBasic;
            cycle.phases[rightPhaseIndex].tPhase = rightTBasic + rightPhase.drawOptions.tProm;
        }

        if (fillEmptyPartMode) {
            /** Временно phaseShiftFromStart не меняется а надо
             *  что-бы менялся для определения rightTBasic либо
             *  надо придумать другой способ */
            rightPhase.drawOptions.phaseShiftFromStart = targetTime;
            initialCycle.time = cycle.phases.reduce((totalTime, phase) => totalTime + phase.tPhase, 0);
        }
    };

    public setCycleShift(id: number, shift: number) {
        const cycle = this.context.editableCooGroupCycle.cycles.find((cycle) => cycle.id === id);

        if (!cycle) return;
        cycle.shift = shift;

        this.updateDrawData();
        this.onChange();
    }

    public readonly setCycleTime = (id: number, time: number) => {
        const cycle = this.context.editableCooGroupCycle.cycles.find((cycle) => cycle.id === id);
        if (!cycle) return;
        cycle.time = time;
        this.setTotalTimeFromCycles();
        this.updateDrawData();
        this.onChange();
    };

    public readonly setCycleSpeed = (id: number, speedKind: SpeedKind, value: number) => {
        const cycle = this.context.editableCooGroupCycle.cycles.find((cycle) => cycle.id === id);
        if (!cycle) return;
        const shouldApplyToAll =
            speedKind === SpeedKind.direct ? this.context.areDirectSpeedsInSync : this.context.areReverseSpeedsInSync;
        if (shouldApplyToAll) this.applySpeedToAllCycles(speedKind, value);
        else this.setCycleSpeedByKind(cycle, speedKind, value);

        this.setTotalTimeFromCycles();
        this.updateDrawData();
        this.onChange();
    };

    public readonly toggleIsSpeedInSync = (speedKind: SpeedKind, cycleId?: number) => {
        let isInSync = false;
        const cycle = this.context.editableCooGroupCycle.cycles.find((cycle) => cycle.id === cycleId);
        if (speedKind === SpeedKind.direct)
            isInSync = this.context.areDirectSpeedsInSync = !this.context.areDirectSpeedsInSync;
        else isInSync = this.context.areReverseSpeedsInSync = !this.context.areReverseSpeedsInSync;
        if (cycle && isInSync) {
            const speed = this.getCycleSpeedByKind(cycle, speedKind);
            this.applySpeedToAllCycles(speedKind, speed);
        }
        this.setTotalTimeFromCycles();
        this.updateDrawData();
        this.onChange();
    };

    /** Берется время самого длиного цикла */
    public setTotalTimeFromCycles = (): void => {
        if (!this.context.editableCooGroupCycle) return;
        this.context.totalTime = Math.max(...this.context.editableCooGroupCycle.cycles.map((cycle) => cycle.time));
        this.context.view.pixelsPerSecond = this.width / this.context.totalTime;
        this.context.view.width = this.width;
    };

    public updateCycle = (cycle: CustomCycle) => {
        const index = this.context.editableCooGroupCycle.cycles.findIndex(
            (item) => item.trafficLight.id === cycle.facilityId
        );
        if (index === -1) return;

        this.context.editableCooGroupCycle.cycles[index] = {
            ...this.context.editableCooGroupCycle.cycles[index],
            ...cycle,
            phases: cycle.phases?.map((item) => ({
                ...item,
                tPhase: item.tPhase === 0 ? item.tBasic + item.tProm : item.tPhase,
            })),
        };

        this.context.initialCooGroupCycle.cycles[index] = {
            ...this.context.editableCooGroupCycle.cycles[index],
            phases: cycle.phases?.map((item) => ({
                ...item,
                tPhase: item.tPhase === 0 ? item.tBasic + item.tProm : item.tPhase,
            })),
        };

        this.setTotalTimeFromCycles();
        this.updateDrawData();
        this.onChange();
    };

    private updateCanvasHeight = () => {
        const bottomTimelineHeight = 24;
        const lastCycle = this.context.cycles[this.context.cycles.length - 1];
        const cycleForwardBottom = lastCycle.y + drawParams.directionBarHeight;
        const backwardHeight = lastCycle.hasBackwardDirection
            ? drawParams.directionBarHeight + drawParams.backwardDirectionGap
            : 0;
        this.context.view.height =
            cycleForwardBottom + backwardHeight + bottomTimelineHeight + drawParams.bottomTimeLineMarginTop;
    };

    private setCycleSpeedByKind(cycle: Cycle, kind: SpeedKind, value: number) {
        if (kind === SpeedKind.direct) return (cycle.directSpeed = value);
        return (cycle.reverseSpeed = value);
    }

    private getCycleSpeedByKind(cycle: Cycle, kind: SpeedKind) {
        if (kind === SpeedKind.direct) return cycle.directSpeed;
        return cycle.reverseSpeed;
    }

    private applySpeedToAllCycles(speedKind: SpeedKind, value: number) {
        let index = 0;
        for (const cycle of this.context.editableCooGroupCycle.cycles) {
            if (index === 0) {
                index++;
                continue;
            }
            this.setCycleSpeedByKind(cycle, speedKind, value);
            index++;
        }
    }

    private initCooGroupCycle() {
        this.setTotalTimeFromCycles();
        this.updateDrawData();
    }

    private readonly setTrafficLightCycles = (): void => {
        const cyclesDistances = this.context.editableCooGroupCycle.cycles.slice(1).map((item) => item.distance);
        const minDistance = Math.min(...cyclesDistances);
        /** Самое короткое растояние в метрах будет равно 60px */
        const minDistanceInPixels = 72;
        /** Вычислить единицу измерения расстояния */
        const pixelPerMin = minDistanceInPixels / minDistance;
        let prevDistance = 0;

        this.context.cycles = this.context.editableCooGroupCycle.cycles.reduce(
            (cycles: CycleView[], cycle, index, list) => {
                if (!this.context.initialCooGroupCycle?.cycles) return cycles;

                const prevCycle = cycles[index - 1];

                const segmentDistance = cycle.distance - prevDistance;
                prevDistance = cycle.distance;

                const cycleView = new CycleView({
                    cycle,
                    index,
                    prevCycle,
                    cyclesList: list,
                    segmentDistance,
                    hasBackwardDirection: this.context.hasBackwardDirection,
                    totalViewTime: this.context.totalTime,
                    visibleCycleRepeatCount: Math.ceil(this.context.totalTime / cycle.time),
                    pixelsPerSecond: this.context.view.pixelsPerSecond,
                    initialCycles: this.context.initialCooGroupCycle.cycles,
                    y: getCycleY({
                        currentCycle: cycle,
                        prevCycle,
                        hasBackwardDirection: this.context.hasBackwardDirection,
                        pixelPerMin,
                    }),
                });

                cycles.push(cycleView);

                return cycles;
            },
            []
        );
    };

    private readonly createPolygons = (): void => {
        for (let index = 0; index < this.context.cycles.length; index++) {
            this.context.cycles[index].createPolygons({
                cycleIndex: index,
                cyclesList: this.context.cycles,
                canvasWidth: this.width,
            });
        }
    };

    // public hitTest = (x: number, y: number): Nullable<DragState> => {
    //     const distanceFromDragElement = 10;
    //     let dragState: Nullable<DragState> = null;
    //     this.context.cycles.forEach((cycle, index) => {
    //         const cycleTop = cycle.y;
    //         const cycleBottom = cycle.y + drawParams.directionBarHeight * 2 + distanceFromDragElement;
    //         const hasYCoincidence = y > cycle.y - distanceFromDragElement && y < cycleBottom;
    //         const isPhaseShiftY = y > cycleTop && y < cycleBottom;

    //         if (!hasYCoincidence) return;

    //         // let indexOfRepeatCycle;
    //         let hasXMatch = false;
    //         const targetXInSeconds = Math.round(x / this.context.view.pixelsPerSecond);

    //         for (let index = 0; index < cycle.visibleCycleRepeatCount; index++) {
    //             const shiftInPixels =
    //                 (cycle.shift + index * cycle.cycleDurationTime) * this.context.view.pixelsPerSecond;
    //             if (shiftInPixels - distanceFromDragElement < x && shiftInPixels + distanceFromDragElement > x) {
    //                 // indexOfRepeatCycle = index;
    //                 hasXMatch = true;
    //             }
    //         }

    //         if (hasXMatch && !isPhaseShiftY) {
    //             dragState = {
    //                 type: "cycle",
    //                 dragElement: cycle,
    //                 repeated: false,
    //             };
    //             // cycle.visibleCycleRepeatDragIndex = indexOfRepeatCycle;
    //         } else {
    //             const currentPhaseIndex = cycle.phases.findIndex(
    //                 (phase) => phase.drawOptions.phaseShiftFromStart + phase.tPhase === targetXInSeconds
    //             );

    //             if (currentPhaseIndex !== -1) {
    //                 const currentPhase = cycle.phases[currentPhaseIndex];
    //                 const nextPhase = cycle.phases[currentPhaseIndex + 1] ?? cycle.phases[0];

    //                 dragState = {
    //                     type: "phase",
    //                     dragElement: [currentPhase, nextPhase],
    //                     cycleIndex: index,
    //                     repeated: true,
    //                 };
    //             }
    //         }
    //     });

    //     return dragState;
    // };
}
