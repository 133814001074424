export type OverlayDataInput = {
    height: number;
    hasBackwardDirection: boolean;
    pixelsPerSecond: number;
    y: number;
    totalViewTime: number;
    cycleDurationTime: number;
    originCycleDurationTime: number;
    directionBarHeight: number;
    directionBarGap: number;
    shift: number;
};

export type OverlayItem = {
    x: number;
    y: number;
    width: number;
    height: number;
};

export type OverlayData = OverlayItem[];

export const createOverlayData = (args?: OverlayDataInput) => {
    const result: OverlayData = [];

    if (!args) return result;

    const count = Math.floor(args.totalViewTime / args.cycleDurationTime);
    const width = (args.cycleDurationTime - args.originCycleDurationTime) * args.pixelsPerSecond;

    for (let i = 0; i < count; i++) {
        const x = (args.shift + args.originCycleDurationTime + i * args.cycleDurationTime) * args.pixelsPerSecond;

        if (args.hasBackwardDirection) {
            const y = args.y + args.directionBarHeight + args.directionBarGap;
            result.push({
                x,
                y,
                width,
                height: args.height,
            });
        }
        result.push({
            x,
            width,
            height: args.height,
            y: args.y,
        });
    }

    return result;
};
