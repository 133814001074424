import { makeAutoObservable } from "mobx";
import { Utils } from "shared";
import { getDateInSeconds } from "./command-launch-popup-state.utils";
import { FieldsOptions, CommandLaunchData } from "./command-launch-popup.types";

enum Mode {
    Duration,
    Completion,
    CycleDuration,
}

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

export class CommandLaunchPopupState {
    public isCycleDurationModeAvailable: boolean;
    public minDurationHours = 0;
    public minDurationMinutes = 0;
    public delayStartDate: NullableDate = null;
    public delayStartTime: string = "";
    public completionDate: NullableDate = null;
    public completionTime: string = "";
    public readonly maxDurationHours = 100;
    public readonly maxDurationMinutes = 59;
    public durationHours?: number;
    public durationMinutes?: number;
    public cycleDurationSeconds?: number;
    public cycleDurationRepeatCount?: number;
    public cycleDurationModeMessage?: string;
    private minDurationSeconds: number;
    private mode: Mode = Mode.Duration;
    private _isDelayEnabled: boolean = false;

    constructor(options: FieldsOptions) {
        this.isCycleDurationModeAvailable = options.isCycleDurationModeAvailable ?? true;
        this.cycleDurationModeMessage = options.cycleDurationModeMessage;
        this.minDurationSeconds = options.minDurationSeconds ?? 0;
        const parsed = this.parseSeconds(this.minDurationSeconds);
        this.minDurationMinutes = parsed.minutes;
        this.minDurationHours = parsed.hours;
        this.cycleDurationSeconds = options.cycleDurationSeconds;
        if (this.cycleDurationSeconds) {
            this.cycleDurationRepeatCount = Math.ceil(this.minDurationSeconds / this.cycleDurationSeconds);
        }
        if (options.defaultDurationSeconds) {
            this.completionDate = new Date(Date.now() + options.defaultDurationSeconds * 1000);
            this.completionTime = Utils.DateUtils.format(this.completionDate, {
                format: Utils.DateUtils.Formats.Time,
                locale: "ru",
            });
            this.completionDate.setHours(0, 0, 0);
            const parsed = this.parseSeconds(options.defaultDurationSeconds);
            // Если часы или минуты равны 0, то меняем на undefined, чтобы в полях отображалась пустота по-умолчанию
            this.durationHours = parsed.hours || void 0;
            this.durationMinutes = parsed.minutes || void 0;
        }
        makeAutoObservable(this);
    }

    public get isCycleDurationMode() {
        return this.mode === Mode.CycleDuration;
    }

    public get isDurationMode() {
        return this.mode === Mode.Duration;
    }

    public get isCompletionMode() {
        return this.mode === Mode.Completion;
    }

    public get isDurationValid() {
        if (!this.durationHours && !this.durationMinutes) return false;
        return this.totalDurationSeconds >= this.minDurationSeconds;
    }

    public get isCycleDurationValid() {
        if (!this.cycleDurationSeconds || !this.cycleDurationRepeatCount) return false;
        return this.cycleDurationSeconds * this.cycleDurationRepeatCount >= this.minDurationSeconds;
    }

    public get totalDurationSeconds() {
        const hoursInSeconds = (this.durationHours ?? 0) * SECONDS_IN_HOUR;
        const minutesInSeconds = (this.durationMinutes ?? 0) * SECONDS_IN_MINUTE;
        return hoursInSeconds + minutesInSeconds;
    }

    public get isDelayEnabled() {
        return this._isDelayEnabled;
    }

    public get isSubmitButtonDisabled() {
        switch (this.mode) {
            case Mode.Duration:
                return !this.isDurationValid;
            case Mode.CycleDuration:
                return !this.isCycleDurationValid;
            default:
                return (!this.completionDate && !this.completionTime) || !this.isCompletionValid;
        }
    }

    public get isCompletionValid() {
        if ((!this.delayStartDate && !this.delayStartTime) || !this.completionDate) return true;

        let start = getDateInSeconds(this.delayStartTime, this.delayStartDate);
        const end = getDateInSeconds(this.completionTime, this.completionDate);

        if (!start) {
            start = new Date().valueOf();
        }
        return start < end;
    }

    public setDurationMode = () => {
        this.mode = Mode.Duration;
        this.resetDelaySettings();
    };

    public setCompletionMode = () => {
        this.mode = Mode.Completion;
    };

    public setCycleDurationMode = () => {
        this.mode = Mode.CycleDuration;
    };

    public changeDurationHours = (value?: number) => {
        this.durationHours = value;
    };

    public changeDurationMinutes = (value?: number) => {
        this.durationMinutes = value;
    };

    public setIsDelayEnabled = (value: NullableBoolean) => {
        this._isDelayEnabled = Boolean(value);
        if (!value) {
            this.resetDelaySettings();
        }
    };

    public changeCompletionDate = (date: NullableDate) => {
        this.completionDate = date;
    };

    public changeStartDate = (date: NullableDate) => {
        this.delayStartDate = date;
    };

    public changeCompletionTime = (time: string) => {
        this.completionTime = time;
    };

    public changeDelayStartTime = (time: string) => {
        this.delayStartTime = time;
    };

    public changeCycleDurationRepeatCount = (value?: number) => {
        this.cycleDurationRepeatCount = value;
    };

    public build() {
        const result: CommandLaunchData = {};

        if (this.isDurationMode) {
            const minutes = this.durationMinutes ?? 0;
            const hoursInMinutes = (this.durationHours ?? 0) * 60;

            if (!minutes && !hoursInMinutes) return;

            result.duration = (hoursInMinutes + minutes) * SECONDS_IN_MINUTE;
        }

        if (this.isCycleDurationMode && this.cycleDurationSeconds && this.cycleDurationRepeatCount) {
            result.duration = this.cycleDurationSeconds * this.cycleDurationRepeatCount;
        }

        if (this.isCompletionMode) {
            result.stopAt = new Date(getDateInSeconds(this.completionTime, this.completionDate) * 1000).toISOString();
        }

        if (this.isDelayEnabled) {
            result.startAt = new Date(getDateInSeconds(this.delayStartTime, this.delayStartDate) * 1000).toISOString();
        }

        return result;
    }

    private parseSeconds(seconds: number) {
        const result = {
            minutes: 0,
            hours: 0,
        };
        if (seconds === 0) return result;
        const hours = seconds / SECONDS_IN_HOUR;
        if (hours < 1) result.minutes = Math.floor(seconds / SECONDS_IN_MINUTE);
        result.hours = Math.floor(seconds / SECONDS_IN_HOUR);
        result.minutes = Math.round((seconds % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);
        return result;
    }

    private resetDelaySettings = () => {
        this.delayStartDate = null;
        this.delayStartTime = "";
    };
}
