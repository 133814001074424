import { BaseApi } from "api/utils";
import { QueryParamsBuilder } from "lib";
import type {
    GetCooGroupListResponse,
    GetCooGroupByIdResponse,
    PostCooGroupResponse,
    PostValidateCooGroupResponse,
    PostCooGroupInput,
    UpdateCooGroupInput,
    UpdateCooGroupResponse,
    GetCooGroupListOptions,
    GetCooGroupLogsFilter,
    GetCooGroupRouteResponse,
    GetCooGroupLogsResponse,
} from "./coo-group-api.types";
import { CooGroupCycle, CooGroupCycleInput, CreateUpdateCycleResult } from "../types";
import { FacilityLogsParamsFilter } from "../facility/facility-logs-params-filter";

export class CooGroupAPI extends BaseApi {
    /** Возвращает все группы координации */
    public getCooGroupList(options?: GetCooGroupListOptions): GetCooGroupListResponse {
        const params = new QueryParamsBuilder()
            .addIterableParam("Status", options?.conditions)
            .addIterableParam("ControlMode", options?.controlModes)
            .addParam("search", options?.search)
            .toString(true)
            .slice(1);
        const url = this.buildUrl("/", params);
        return this.request({ url });
    }

    /** Возвращает группу координации по id */
    public getCooGroupById(id: number, includeState = true): GetCooGroupByIdResponse {
        const params = new QueryParamsBuilder().addParam("includeState", `${includeState}`).toString();
        const url = this.buildUrl(`/${id}`, params);
        return this.request({ url });
    }

    public getCooGroupCycles(id: number): Promise<CooGroupCycle[]> {
        const url = this.buildUrl(`/${id}/cycle`);
        return this.request({ url });
    }

    public getCooGroupLogs(id: number, filter: GetCooGroupLogsFilter): GetCooGroupLogsResponse {
        const paramsString = new FacilityLogsParamsFilter(filter).toString();
        const url = this.buildUrl(`/${id}/logs`, paramsString);
        return this.request({ url });
    }

    /** Создаёт новую группу координации */
    public postCooGroup(body: PostCooGroupInput): PostCooGroupResponse {
        const url = this.buildUrl("");
        return this.request({ url, method: "POST", body: JSON.stringify(body) });
    }

    public updateCooGroup(body: UpdateCooGroupInput): UpdateCooGroupResponse {
        const url = this.buildUrl(`/${body.id}`);
        return this.request({ url, method: "PUT", body: JSON.stringify(body) });
    }

    /** Валидация группы координации */
    public postValidateCooGroup(body: PostCooGroupInput): PostValidateCooGroupResponse {
        const url = this.buildUrl("/validation");
        return this.request({ url, method: "POST", body: JSON.stringify(body) });
    }

    public validateCycle(id: number, cycle: CooGroupCycleInput): Promise<string[]> {
        const url = this.buildUrl(`/${id}/cycle/validation`);
        return this.request({ url, method: "POST", body: JSON.stringify(cycle) });
    }

    /** Создание программы группы координации */
    public async createCooGroupCycle(
        cooGroupId: number,
        program: CooGroupCycleInput
    ): Promise<CreateUpdateCycleResult> {
        const url = this.buildUrl(`/${cooGroupId}/cycle`);
        const response = (await this.request({
            url,
            method: "POST",
            body: JSON.stringify(program),
        })) as any;

        return {
            type: !!response.error ? "error" : "cycle",
            data: response,
        };
    }

    /** Обновление программы группы координации */
    public async updateCooGroupCycle(
        cooGroupId: number,
        program: CooGroupCycleInput
    ): Promise<CreateUpdateCycleResult> {
        const url = this.buildUrl(`/${cooGroupId}/cycle`);
        const response = (await this.request({ url, method: "PUT", body: JSON.stringify(program) })) as any;

        return {
            type: !!response.error ? "error" : "cycle",
            data: response,
        };
    }

    /** Удаление группы координации */
    public deleteCooGroup(cooGroupId: number): Promise<void> {
        const url = this.buildUrl(`/${cooGroupId}`);
        return this.request({ url, method: "DELETE", as: "raw" });
    }

    /** Удаление программы группы координации */
    public deleteCooGroupCycle(cooGroupId: number, cooGroupCycleId: number): Promise<void> {
        const url = this.buildUrl(`/${cooGroupId}/cycle/${cooGroupCycleId}`);
        return this.request({ url, method: "DELETE", as: "raw" });
    }

    /** Возвращает набор светофоров и их направления, которые легли на маршрут */
    public getCooGroupRoute = (coordinates: LatLng[]): GetCooGroupRouteResponse => {
        const url = this.buildUrl("/route");
        return this.request({ url, method: "POST", body: JSON.stringify(coordinates) });
    };

    protected buildUrl(endpoint: string = "/", params?: string) {
        return super.buildUrl(`/coogroup${endpoint}${params ? `?${params}` : ""}`);
    }
}
