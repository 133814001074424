import { useRef, useEffect, memo } from "react";
import { TrackPainter } from "lib";
import { MapFragment } from "../../map-fragment";
import * as Markup from "./phase-media.styles";

export type PhaseMediaProps = {
    lat: number;
    lng: number;
    trackDirections: Array<TrackPainter.PainterTrack[]>;
    size?: number;
    alt?: string;
    className?: string;
};

const PhaseMediaComponent = (props: PhaseMediaProps) => {
    const { trackDirections, size = 102, className, ...mapFragmentProps } = props;
    const refPreview = useRef<HTMLCanvasElement | null>(null);
    const painter = useRef<TrackPainter.Painter | null>(null);

    useEffect(() => {
        if (!refPreview.current) return;
        painter.current = new TrackPainter.Painter(refPreview.current);
        return () => {
            painter.current = null;
        };
    }, []);

    useEffect(() => {
        const trackPainter = painter.current;
        if (!trackPainter) return;

        trackPainter.clear();
        trackDirections.forEach((direction) => {
            trackPainter.drawTracks(direction);
        });
    }, [trackDirections]);

    return (
        <Markup.Container className={className}>
            <Markup.MapFragmentContainer>
                <MapFragment {...mapFragmentProps} size={size} />
            </Markup.MapFragmentContainer>
            <Markup.TracksCanvas width={size} height={size} ref={refPreview} />
        </Markup.Container>
    );
};

export const PhaseMedia = memo(PhaseMediaComponent);
