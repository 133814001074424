import { CooGroupDomain, TrafficLightDomain } from "app-domain";
import { action, computed, makeObservable, observable } from "mobx";
import { FormTextField } from "lib";
import { Form } from "./abstract-form";

type PhaseGroupData = {
    name: string;
    phases: TrafficLightDomain.Phase[];
};

type GetTrafficLightPhaseGroupsResult = {
    directionNumber: number;
    groups: PhaseGroupData[];
};

type ActivePhasesDict = {
    [key: number]: number;
};

export class GreenStreetForm extends Form {
    public activePhases: ActivePhasesDict = {};
    private initialActivePhases: ActivePhasesDict = {};

    constructor(private cooGroup: CooGroupDomain.CooGroup, cycle: CooGroupDomain.CustomCycle) {
        super(cycle);
        makeObservable(this, {
            activePhases: observable,
            setActivePhaseNumber: action,
            hasChanges: computed,
        });
        this.setInitialState();
    }

    public get isSubmitDisabled() {
        if (this.isNew)
            return (
                this.name.value.trim().length === 0 ||
                Object.keys(this.activePhases).length !== this.cooGroup.facilities.length
            );
        return !this.hasChanges;
    }

    public get hasChanges() {
        return (
            this.isNameChanged ||
            Object.keys(this.activePhases).length !== this.cooGroup.facilities.length ||
            this.hasPhaseChanges()
        );
    }

    public setCycle(cycle: CooGroupDomain.Cycle) {
        this.cycle = cycle;
    }

    public build(): CooGroupDomain.CustomCycle {
        return {
            id: this.cycle.id,
            cooGroupId: this.cooGroup.id,
            isEnabled: this.cycle.isEnabled,
            type: this.cycle.type,
            name: this.name.value,
            purpose: this.cycle.purpose,
            createdDate: this.cycle.createdDate,
            isActiveNow: this.cycle.isActiveNow,
            greenLinePhases: Object.entries(this.activePhases).map(([facilityId, phaseNumber]) => ({
                phaseNumber,
                facilityId: Number(facilityId),
            })),
        };
    }

    public reset() {
        this.setInitialState();
    }

    public getActivePhaseNumber(trafficLightId: number): NullableNumber {
        return this.activePhases[trafficLightId] ?? null;
    }

    public setActivePhaseNumber(trafficLightId: number, phaseNumber: number) {
        this.activePhases[trafficLightId] = phaseNumber;
    }

    /**
     *  Возвращает группы фаз, соответстующие определенному направлению
     */
    public getTrafficLightPhaseGroups(
        trafficLight: TrafficLightDomain.TrafficLight
    ): Nullable<GetTrafficLightPhaseGroupsResult> {
        const directionNumber = this.cooGroup.facilities.find(
            (facility) => facility.facilityId === trafficLight.id
        )?.directionNumber;
        if (!directionNumber) return null;
        return {
            directionNumber,
            groups: trafficLight.phasesByGroups.reduce((result, group) => {
                const items = group.items.filter((phase) => phase.directionByNum[directionNumber]);
                if (!items.length) return result;
                result.push({
                    name: group.name,
                    phases: items,
                });
                return result;
            }, [] as PhaseGroupData[]),
        };
    }

    private hasPhaseChanges() {
        const initialValues = Object.values(this.initialActivePhases);
        const currentValues = Object.values(this.activePhases);
        return initialValues.some((initialValue, index) => initialValue !== currentValues[index]);
    }

    private setInitialState() {
        this.name = new FormTextField(this.cycle.name);
        this.setActivePhases();
    }

    private setActivePhases() {
        this.cycle.greenLinePhases?.forEach((phase) => {
            this.activePhases[phase.facilityId] = phase.phaseNumber;
            this.initialActivePhases[phase.facilityId] = phase.phaseNumber;
        }, {});
    }
}
