import { DirectionTypeCode } from "app-domain/traffic-light/enums";
import CarIconURL from "diagrams/icons/car.svg";
import BusIconURL from "diagrams/icons/bus.svg";
import PedestrianIconURL from "diagrams/icons/pedestrian.svg";

export const getTrafficLightDirectionIconURL = (directionType: DirectionTypeCode): string => {
    switch (directionType) {
        case DirectionTypeCode.Pedestrian:
            return PedestrianIconURL;
        case DirectionTypeCode.PublicTransport:
            return BusIconURL;
        case DirectionTypeCode.Transport:
            return CarIconURL;
        default:
            return "";
    }
};
