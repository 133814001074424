import { CanvasObject, CanvasObjectEvents } from "./canvas-object";
import { Container } from "./container";
import { InteractionEvent } from "./interaction-event";

type SupportedEvents = "click" | "mousemove";

const eventTypeMap = {
    click: "click",
    mousemove: "mousemove",
    mousedown: "mousedown",
} as {
    [key in SupportedEvents]: keyof CanvasObjectEvents;
};

export class InteractionManager {
    private static lastObjectStatic: Nullable<CanvasObject> = null;
    private lastObject: CanvasObject | null = null;

    constructor(private canvas: HTMLCanvasElement, private rootObject: Container) {
        global.addEventListener("mousemove", this._onMouseMove);
        this.canvas.addEventListener("click", this._hitTestOnEvent);
        this.canvas.addEventListener("mousedown", this._hitTestOnEvent);
    }

    public destroy() {
        global.removeEventListener("mousemove", this._onMouseMove);
        this.canvas.removeEventListener("click", this._hitTestOnEvent);
        this.canvas.removeEventListener("mousedown", this._hitTestOnEvent);
        this.lastObject = null;
    }

    public static hitTestObject(x: number, y: number, object: Container | CanvasObject) {
        if (object instanceof Container) {
            object.children.forEach((child) => InteractionManager.hitTestObject(x, y, child));
        } else {
            const isInBounds = object.getBounds?.().includesPoint(x, y);
            if (isInBounds) {
                if (this.lastObjectStatic && object.zIndex < this.lastObjectStatic.zIndex) return;
                this.lastObjectStatic = object;
            }
        }
        const result = this.lastObjectStatic;
        this.lastObjectStatic = null;
        return result;
    }

    private _hitTestOnEvent = (e: MouseEvent) => {
        this._hitTestContainer(e, this.rootObject);
        const objectEvent: keyof CanvasObjectEvents | undefined = eventTypeMap[e.type as SupportedEvents];
        if (!objectEvent || !this.lastObject) return;
        const event = new InteractionEvent(e, this.lastObject);
        this.lastObject.emit(objectEvent, event);
    };

    private _hitTestContainer = (e: MouseEvent, object: Container) => {
        if (!object.isInteractive) return;
        for (const obj of object.children) {
            if (!obj.isInteractive) continue;
            this._hitTestChild(e, obj);
        }
    };

    private _hitTestChild = (e: MouseEvent, child: Container | CanvasObject) => {
        const { offsetX, offsetY } = e;
        if (child instanceof Container) this._hitTestContainer(e, child);
        const isInBounds = child.getBounds?.().includesPoint(offsetX, offsetY);
        if (!isInBounds) return;
        if (this.lastObject && child.zIndex < this.lastObject.zIndex) return;
        this.lastObject = child;
    };

    private _updateCursor = () => {
        document.body.style.cursor = this.lastObject?.cursor ?? "default";
        this.lastObject = null;
    };

    private _onMouseMove = (e: MouseEvent) => {
        if (e.target !== this.canvas) return;
        this._hitTestContainer(e, this.rootObject);
        this._updateCursor();
    };
}
