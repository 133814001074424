import { computed, makeAutoObservable, observable } from "mobx";
import { TrafficLightDomain } from "app-domain";
import { TrafficLightHistory } from "services";
import { CodeListFilter, DateTimeShapeFilter } from "lib";
import { DateUtils } from "shared/utils";
import set from "date-fns/set";

export class Filter {
    public isInitial = true;
    public startDate = new DateTimeShapeFilter({ time: "00:00" });
    public endDate = new DateTimeShapeFilter({ time: "00:00" });
    public eventTypes = new CodeListFilter<TrafficLightDomain.Enums.EventTypeCode>();
    public controlModes = new CodeListFilter<TrafficLightDomain.Enums.ControlModeCode>();
    public statuses = new CodeListFilter<TrafficLightDomain.Enums.StatusCode>();
    public malfunctionTypes = new CodeListFilter<TrafficLightDomain.Enums.MalfunctionType>();

    constructor() {
        makeAutoObservable(this, {
            isValidStartEndDate: computed,
            startDate: observable.ref,
            endDate: observable.ref,
        });
    }

    get isValidStartEndDate(): boolean {
        const isDate = this.startDate.date && this.endDate.date;
        if (!isDate) return true;
        const isValidDate = this.startDate <= this.endDate;
        const isTime = this.startDate.time && this.endDate.time;

        if (!isTime) return isValidDate;

        if (this.startDate.date?.getTime() === this.endDate.date?.getTime()) {
            return this.startDate.time < this.endDate.time && isValidDate;
        }

        return isValidDate;
    }

    public reset() {
        this.isInitial = true;
        this.startDate.reset();
        this.endDate.reset();
        this.eventTypes.reset();
        this.controlModes.reset();
        this.statuses.reset();
        this.malfunctionTypes.reset();
    }

    public toServiceFilter() {
        const res: TrafficLightHistory.Types.EventsFilter = {
            eventTypes: this.eventTypes.list,
            controlModes: this.controlModes.list,
            malfunctionTypes: this.malfunctionTypes.list,
            statuses: this.statuses.list,
        };
        const isStartValid = this.isValidDate(this.startDate);
        const isEndValid = this.isValidDate(this.endDate);
        if (isStartValid && isEndValid) {
            res.start = this.formatDateForService(this.startDate);
            res.finish = this.formatDateForService(this.endDate);
        }
        return res;
    }

    /** Признак пустоты фильтра */
    public get isEmpty() {
        return (
            this.startDate.isEmpty &&
            this.endDate.isEmpty &&
            this.eventTypes.isEmpty &&
            this.controlModes.isEmpty &&
            this.statuses.isEmpty &&
            this.malfunctionTypes.isEmpty
        );
    }

    private isValidDate = (shape: DateTimeShapeFilter) => {
        return shape.allDay ? shape.date !== null : Boolean(shape.date && shape.time);
    };

    private formatDateForService(shape: DateTimeShapeFilter) {
        if (!shape.date) return "";
        if (shape.allDay) {
            return DateUtils.formatToISOTzString(shape.date);
        }
        const [hours = 0, minutes = 0] = shape.time.split(":");
        const updatedDate = set(new Date(shape.date), { hours: Number(hours), minutes: Number(minutes) });
        return DateUtils.formatToISOTzString(updatedDate);
    }
}
