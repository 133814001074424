import { makeAutoObservable, observable, reaction, runInAction } from "mobx";
import throttle from "lodash/throttle";
import { TrafficLightDomain, Common } from "app-domain";
import { TrafficLights } from "services";
import { FileHelper } from "lib";
import { Filter } from "./filter";
import { Selection } from "../selection";
import * as utils from "./traffic-light-store.utils";

export class TrafficLightStore {
    public isListLoading: boolean = false;
    public isInitializingTrafficLight = false;
    public serverInfo?: Common.ServerInfo;
    public readonly filter: Filter = new Filter();
    public plannedGovernanceGroup: TrafficLightDomain.PlannedGovernanceInfo[] = [];
    private selectedItemId: NullableNumber = null;
    private selectedItems = new Selection();
    private filterChangeEffect!: VoidFunction;
    private trafficLights: TrafficLightDomain.ITrafficLightMap = new Map();
    private _filteredIds: Set<number> = new Set();
    private _isDownloadingPassport = false;

    constructor(private trafficLightService: TrafficLights.TrafficLightService) {
        makeAutoObservable(this, {
            serverInfo: observable.ref,
        });
        this.setUpEffects();
    }

    public get plannedGovernance(): TrafficLightDomain.PlannedGovernance[] {
        return utils.convertPlannedGovernanceGroup(
            this.plannedGovernanceGroup as TrafficLightDomain.PlannedGovernanceInfo[]
        );
    }

    public get itemList() {
        return Array.from(this.trafficLights.values());
    }

    public get filteredList() {
        const cooGroup: TrafficLightDomain.TrafficLight[] = [];
        this._filteredIds.forEach((id) => {
            const item = this.trafficLights.get(id);
            if (item) cooGroup.push(item);
        });
        return cooGroup;
    }

    public get count() {
        return this.trafficLights.size;
    }

    public get isDownloadingPassport() {
        return this._isDownloadingPassport;
    }

    public get firstInSelection() {
        const id = this.selectedItems.first;
        if (!id) return null;
        return this.getById(id);
    }

    public get plannedGovernanceGroupCount() {
        return this.plannedGovernanceGroup.reduce((acc: number, cur: TrafficLightDomain.PlannedGovernanceInfo) => {
            acc += cur.plannedGovernances.length;
            return acc;
        }, 0);
    }

    public loadItems = async () => {
        if (!this.serverInfo) return;
        this.setIsListLoading(true);
        const map = await this.trafficLightService.getTrafficLightMap({ serverInfo: this.serverInfo });
        this.setIsListLoading(false);
        runInAction(() => {
            this.trafficLights = map;
            this._filteredIds = new Set(map.keys());
        });
    };

    public async loadById(id: number) {
        const trafficLight = this.getById(id);
        /** TODO: Позже переделать создание СО */
        if (!trafficLight) return null;
        const data = await this.trafficLightService.getTrafficLightMetaData(id);
        trafficLight.setMetaData(data);
        return trafficLight;
    }

    /** Загружает метаданные объектов по их id */
    public loadItemsDataByIds(ids: number[]) {
        return Promise.all(ids.map(this.loadById.bind(this)));
    }

    public loadGovernancePlanned = async (facilityId: number) => {
        if (this.selectedItemId !== facilityId) return;
        const governancePlanned = await this.trafficLightService.getGovernancePlanned(facilityId);
        runInAction(() => {
            if (!!governancePlanned && Array.isArray(governancePlanned)) {
                this.plannedGovernanceGroup = governancePlanned as TrafficLightDomain.PlannedGovernanceInfo[];
            } else {
                this.plannedGovernanceGroup = [];
            }
        });
    };

    public removeGovernancePlanned = async (facilityId: number, facilityPlannedGovernanceId: number) => {
        await this.trafficLightService.removePlannedGovernance(facilityId, facilityPlannedGovernanceId);
        await this.loadGovernancePlanned(facilityId);
    };

    public getById(id: number) {
        return this.trafficLights.get(id) ?? null;
    }

    public async downloadTrafficLightPassport(id: number) {
        const trafficLight = this.getById(id);
        if (!trafficLight || !trafficLight.passportId) return;
        this.setIsDownloadingPassport(true);
        const file = await this.trafficLightService.getTrafficLightPassport(id, trafficLight.passportId);
        FileHelper.downloadFile(file);
        this.setIsDownloadingPassport(false);
    }

    public setTrafficLightId(id: NullableNumber) {
        this.selectedItemId = id;
    }

    public isSelectedItem(id: number) {
        return this.selectedItems.isSelected(id);
    }

    public toggleSelection(id: number) {
        this.selectedItems.toggle(id);
    }

    public chooseItem(id: number) {
        this.selectedItems.clear();
        this.selectedItems.add(id);
    }

    public clearSelectedItems() {
        this.selectedItems.clear();
    }

    public setLocalAdaptiveModuleEnabled(facilityId: number, value: boolean) {
        this.trafficLightService.setLocalAdaptiveModuleEnabled(facilityId, value);
    }

    public async removeCycle(facilityId: number, cycleId: number) {
        await this.trafficLightService.removeCycle(cycleId);
        const trafficLight = this.trafficLights.get(facilityId);
        if (!trafficLight) return;
        trafficLight.cycles = trafficLight.cycles.filter((cycle) => cycle.id !== cycleId);
    }

    public destroy = () => {
        this.filterChangeEffect();
    };

    private setIsDownloadingPassport(value: boolean) {
        this._isDownloadingPassport = value;
    }

    private setIsListLoading(value: boolean) {
        this.isListLoading = value;
    }

    public search = async () => {
        if (!this.serverInfo) return;
        this.setIsListLoading(true);
        const map = await this.trafficLightService.getTrafficLightMap({
            serverInfo: this.serverInfo,
            ...this.filter.shape,
        });
        this.setIsListLoading(false);
        runInAction(() => {
            const filteredIds = new Set<number>();
            for (let cooGroup of map.values()) {
                filteredIds.add(cooGroup.id);
            }
            this._filteredIds = filteredIds;
        });
    };

    private readonly throttledLoadItems = throttle(this.search, 500, { leading: false, trailing: true });

    private setUpEffects() {
        this.filterChangeEffect = reaction(() => this.filter.shape, this.throttledLoadItems);
    }
}
