import { memo, useMemo } from "react";

import { PositionScale, SliderType } from "./slider.types";

import * as Markup from "./slider.styles";

export const Slider = memo(({className, ...props}: SliderType) => {
    const {isInputVisible = true, positionScale = PositionScale.bottom, lastScaleCaption } = props;

    const lastCaption = useMemo(() => typeof lastScaleCaption === "string" ? 
        <Markup.ScaleLastCaptionWrapper>
            <Markup.ScaleLastCaption>
                {lastScaleCaption}
            </Markup.ScaleLastCaption>
        </Markup.ScaleLastCaptionWrapper>
        : lastScaleCaption, [lastScaleCaption]);

    return <Markup.SliderContainer className={className}>
        <Markup.SliderWrapper isInputHide={!isInputVisible} positionScale={positionScale}>
            {lastCaption}
            <Markup.Slider {...props} />
        </Markup.SliderWrapper>
    </Markup.SliderContainer>
});