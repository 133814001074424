import { memo, useEffect, useState, useRef, useCallback } from "react";
import { TagLabel } from "../../tag-label";

type Props = {
    seconds: number;
    hash?: Symbol;
};

const prepareValue = (seconds: NullableNumber) => {
    if (seconds === null || !Number.isInteger(seconds)) return null;
    return seconds < 0 ? null : seconds;
};

const PhaseTimerComponent = ({ seconds, hash }: Props) => {
    const timerId = useRef<NodeJS.Timeout | null>(null);
    const [valueCountDown, changeCountDown] = useState<NullableNumber>(null);

    const clearInterval = useCallback(() => {
        if (typeof timerId.current === "number") global.clearInterval(timerId.current);
        timerId.current = null;
    }, []);

    useEffect(() => {
        clearInterval();

        changeCountDown(prepareValue(seconds));

        timerId.current = setInterval(() => {
            changeCountDown((prev: number | null) => {
                if (prev === null) return prev;

                const next = prepareValue(prev - 1);

                if (next === null) {
                    clearInterval();
                }

                return next;
            });
        }, 1000);

        return () => {
            clearInterval();
        };
    }, [hash, seconds, clearInterval]);

    if (!valueCountDown) return null;

    return <TagLabel>{valueCountDown}</TagLabel>;
};

export const PhaseTimer = memo(PhaseTimerComponent);
