import { ReactNode, memo } from "react";
import * as Markup from "./list-panel.styles";

export type ListPanelProps = {
    header: ReactNode;
    list: ReactNode;
};

export const ListPanel = memo((props: ListPanelProps) => {
    const { header, list } = props;
    return (
        <Markup.Panel>
            <Markup.Header>{header}</Markup.Header>
            <Markup.List>{list}</Markup.List>
        </Markup.Panel>
    );
});
