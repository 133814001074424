import type { LngLat } from "./route-building-controller.types";

export enum Events {
    change = "@asudd.active-trafficlight-custom.change",
    addMarker = "@asudd.route.add-marker",
    moveMarker = "@asudd.move-marker",
}

export type Handlers = {
    [Events.change]: (id: number) => void;
    [Events.addMarker]: (lngLat: LngLat) => void;
    [Events.moveMarker]: (props: { id: number; lngLat: LngLat }) => void;
};
