import { useEffect, useState } from "react";

const second = 1000;

export const useTimer = () => {
    const [startTime, setStartTime] = useState<NullableNumber>(null);
    const [time, setTime] = useState<NullableNumber>(null);

    useEffect(() => {
        if (!startTime) return;

        const interval = setInterval(() => {
            const next = Math.round((Date.now() - startTime) / second);
            setTime(next);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [startTime]);

    return { time, setStartTime };
};
