export type TrafficLightAnalyticsInfo = {
    percent: number,
    phase?: number,
    seconds: number,
    status?: number,
    type: "phase" | "status"
};

export type TrafficLightAnalytics = {
    /** Дата */
    period: number;
    /** Аналитическая информация */
    info: TrafficLightAnalyticsInfo[];
};

export type TrafficLightAnalyticsError = {
    /** Ошибка */
    error: string;
};

export enum AnalyticsSplit {
    phases5m = "phases5m",
    phases15m = "phases15m",
    phases30m = "phases30m",
    phasesDay = "phasesDay",
    phasesHour = "phasesHour",
}

export type AnalyticsBySplit = Record<AnalyticsSplit, TrafficLightAnalytics[]>;
