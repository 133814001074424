import { BaseApi } from "api/utils";
import { QueryParamsBuilder } from "lib";

import { Types } from "shared";
import { FacilityData, FacilitySnapshot } from "../types";
import {
    GetFacilityLogsArgs,
    GetFacilityHistorySnapshotArgs,
    GetFacilitiesOptions,
    GetFacilityIssuesOptions,
    FacilityIssuesResponseData,
    GetDetectorLogsResponseData,
    GetFacilitiesAnalyticsArgs,
    PromiseResponse,
} from "./facility.types";
import { FacilityLogsParamsFilter } from "./facility-logs-params-filter";

export class FacilityAPI extends BaseApi {
    private baseURL = "/facility";

    /** Получает список светофорных объектов */
    public getFacilities(options?: GetFacilitiesOptions): Promise<FacilityData[]> {
        const params = new QueryParamsBuilder()
            .addIterableParam("status", options?.statuses)
            .addIterableParam("condition", options?.conditions)
            .addIterableParam("controlMode", options?.controlModes)
            .addParam("search", options?.search)
            .toString(true);
        const url = super.buildUrl(this.baseURL + params);
        return this.request({ url });
    }

    /** Возращает светофорный объект с текущей версией паспорта */
    public getFacility(id: number, includeState = false, includeUserGovernance = false): Promise<FacilityData> {
        const url = this.buildUrl(
            `/${id}`,
            `includeState=${includeState}&includeUserGovernance=${includeUserGovernance}`
        );
        return this.request({ url });
    }

    /** Получает историю состояний СО */
    public getFacilityLogs(args: GetFacilityLogsArgs): Promise<Types.IPagedCollection<FacilitySnapshot>> {
        const { facilityId, ...rest } = args;
        const paramsString = new FacilityLogsParamsFilter(rest).toString();
        const url = this.buildUrl(`/${facilityId}/logs`, paramsString);
        return this.request({ url });
    }

    /** Получает историю состояний детекторов установленных на светофорных объектах */
    public getDetectorLogs(args: GetFacilityLogsArgs): Promise<GetDetectorLogsResponseData> {
        const { facilityId, ...rest } = args;
        const paramsString = new FacilityLogsParamsFilter(rest).toString();
        const url = this.buildUrl(`/${facilityId}/detector/logs`, paramsString);
        return this.request({ url });
    }

    public getFacilityIssues({
        isActive,
        facilityId,
    }: GetFacilityIssuesOptions): Promise<FacilityIssuesResponseData[]> {
        const url = this.buildUrl(`/${facilityId}/issues/${isActive ? "active" : ""}`);
        return this.request({ url });
    }

    /** Получает файл журнала снепшотов СО */
    public async getHistorySnapshot(args: GetFacilityHistorySnapshotArgs): Promise<Response> {
        const { filter, facilityId } = args;
        const paramsString = new FacilityLogsParamsFilter({ filter }).toString();
        const url = this.buildUrl(`/${facilityId}/snapshot/history`, paramsString);
        return this.request({ url, as: "raw" });
    }

    /** Получает СО по id */
    public async getBatchedFacilities(ids: number[], includeState: boolean = false): Promise<(FacilityData | null)[]> {
        const queryParamsBuilder = new QueryParamsBuilder();
        const queryParams = queryParamsBuilder.addParam("includeState", includeState.toString()).toString();
        const url = this.buildUrl(`/batch`, queryParams);
        return this.request({ url, method: "POST", body: JSON.stringify(ids) });
    }

    /** Получает аналитику СО по id */
    public async getFacilityAnalytics(args: GetFacilitiesAnalyticsArgs): Promise<PromiseResponse> {
        const queryParamsBuilder = new QueryParamsBuilder();
        const { facilityId, date } = args;
        const queryParams = queryParamsBuilder.addParam("date", date).toString();
        const url = this.buildUrl(`/${facilityId}/distribution`, queryParams);
        const response = await this.request({ url, method: "GET", as: "raw"});
        if (response.status === 200) return response.json();
        else return { error: "Ошибка" };
    }

    public getFacilityPassport(id: number, version: number): Promise<Blob> {
        const url = this.buildUrl(`/${id}/version/${version}/passport`);
        return this.request({ url, as: "blob" });
    }

    protected buildUrl(endPoint: string, paramsString: string = "") {
        return super.buildUrl(`${this.baseURL}${endPoint}?${paramsString}`);
    }
}
